/* tslint:disable */
/* eslint-disable */
/**
 * eminence-pac-system
 * eminence-pac-system API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { HTTPValidationError } from '../models';
// @ts-ignore
import { ImagingCentreCharges } from '../models';
// @ts-ignore
import { ImagingCentreChargesCreate } from '../models';
// @ts-ignore
import { ImagingCentreChargesUpdate } from '../models';
/**
 * ImagingCentreChargesApi - axios parameter creator
 * @export
 */
export const ImagingCentreChargesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Clone Imaging Centre Charge
         * @param {number} centreIdFrom 
         * @param {number} centreIdTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneImagingCentreCharge: async (centreIdFrom: number, centreIdTo: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'centreIdFrom' is not null or undefined
            assertParamExists('cloneImagingCentreCharge', 'centreIdFrom', centreIdFrom)
            // verify required parameter 'centreIdTo' is not null or undefined
            assertParamExists('cloneImagingCentreCharge', 'centreIdTo', centreIdTo)
            const localVarPath = `/api/v1/imaging_centre/charges/clone`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (centreIdFrom !== undefined) {
                localVarQueryParameter['centre_id_from'] = centreIdFrom;
            }

            if (centreIdTo !== undefined) {
                localVarQueryParameter['centre_id_to'] = centreIdTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Imagingcentre Charge
         * @param {ImagingCentreChargesCreate} imagingCentreChargesCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createImagingCentreCharge: async (imagingCentreChargesCreate: ImagingCentreChargesCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'imagingCentreChargesCreate' is not null or undefined
            assertParamExists('createImagingCentreCharge', 'imagingCentreChargesCreate', imagingCentreChargesCreate)
            const localVarPath = `/api/v1/imaging_centre/charges`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(imagingCentreChargesCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Imagingcentre Charges Bulk
         * @param {Array<ImagingCentreChargesCreate>} imagingCentreChargesCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createImagingCentreChargesBulk: async (imagingCentreChargesCreate: Array<ImagingCentreChargesCreate>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'imagingCentreChargesCreate' is not null or undefined
            assertParamExists('createImagingCentreChargesBulk', 'imagingCentreChargesCreate', imagingCentreChargesCreate)
            const localVarPath = `/api/v1/imaging_centre/charges/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(imagingCentreChargesCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Imagingcentre Charge
         * @param {number} chargeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImagingCentreCharge: async (chargeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chargeId' is not null or undefined
            assertParamExists('deleteImagingCentreCharge', 'chargeId', chargeId)
            const localVarPath = `/api/v1/imaging_centre/charges/{charge_id}`
                .replace(`{${"charge_id"}}`, encodeURIComponent(String(chargeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Imagingcentre Charges
         * @param {number} imagingCentreId 
         * @param {string} modality 
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImagingCentreCharges: async (imagingCentreId: number, modality: string, sort?: string, range?: string, filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'imagingCentreId' is not null or undefined
            assertParamExists('getImagingCentreCharges', 'imagingCentreId', imagingCentreId)
            // verify required parameter 'modality' is not null or undefined
            assertParamExists('getImagingCentreCharges', 'modality', modality)
            const localVarPath = `/api/v1/imaging_centre/{imagingCentre_id}/charges`
                .replace(`{${"imagingCentre_id"}}`, encodeURIComponent(String(imagingCentreId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (modality !== undefined) {
                localVarQueryParameter['modality'] = modality;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (range !== undefined) {
                localVarQueryParameter['range'] = range;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Imagingcentre Charge
         * @param {number} chargeId 
         * @param {ImagingCentreChargesUpdate} imagingCentreChargesUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateImagingCentreCharge: async (chargeId: number, imagingCentreChargesUpdate: ImagingCentreChargesUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chargeId' is not null or undefined
            assertParamExists('updateImagingCentreCharge', 'chargeId', chargeId)
            // verify required parameter 'imagingCentreChargesUpdate' is not null or undefined
            assertParamExists('updateImagingCentreCharge', 'imagingCentreChargesUpdate', imagingCentreChargesUpdate)
            const localVarPath = `/api/v1/imaging_centre/charges/{charge_id}`
                .replace(`{${"charge_id"}}`, encodeURIComponent(String(chargeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(imagingCentreChargesUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ImagingCentreChargesApi - functional programming interface
 * @export
 */
export const ImagingCentreChargesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ImagingCentreChargesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Clone Imaging Centre Charge
         * @param {number} centreIdFrom 
         * @param {number} centreIdTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cloneImagingCentreCharge(centreIdFrom: number, centreIdTo: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cloneImagingCentreCharge(centreIdFrom, centreIdTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Imagingcentre Charge
         * @param {ImagingCentreChargesCreate} imagingCentreChargesCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createImagingCentreCharge(imagingCentreChargesCreate: ImagingCentreChargesCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImagingCentreCharges>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createImagingCentreCharge(imagingCentreChargesCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Imagingcentre Charges Bulk
         * @param {Array<ImagingCentreChargesCreate>} imagingCentreChargesCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createImagingCentreChargesBulk(imagingCentreChargesCreate: Array<ImagingCentreChargesCreate>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createImagingCentreChargesBulk(imagingCentreChargesCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Imagingcentre Charge
         * @param {number} chargeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteImagingCentreCharge(chargeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteImagingCentreCharge(chargeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Imagingcentre Charges
         * @param {number} imagingCentreId 
         * @param {string} modality 
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getImagingCentreCharges(imagingCentreId: number, modality: string, sort?: string, range?: string, filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ImagingCentreCharges>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getImagingCentreCharges(imagingCentreId, modality, sort, range, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Imagingcentre Charge
         * @param {number} chargeId 
         * @param {ImagingCentreChargesUpdate} imagingCentreChargesUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateImagingCentreCharge(chargeId: number, imagingCentreChargesUpdate: ImagingCentreChargesUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImagingCentreCharges>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateImagingCentreCharge(chargeId, imagingCentreChargesUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ImagingCentreChargesApi - factory interface
 * @export
 */
export const ImagingCentreChargesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ImagingCentreChargesApiFp(configuration)
    return {
        /**
         * 
         * @summary Clone Imaging Centre Charge
         * @param {number} centreIdFrom 
         * @param {number} centreIdTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneImagingCentreCharge(centreIdFrom: number, centreIdTo: number, options?: any): AxiosPromise<any> {
            return localVarFp.cloneImagingCentreCharge(centreIdFrom, centreIdTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Imagingcentre Charge
         * @param {ImagingCentreChargesCreate} imagingCentreChargesCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createImagingCentreCharge(imagingCentreChargesCreate: ImagingCentreChargesCreate, options?: any): AxiosPromise<ImagingCentreCharges> {
            return localVarFp.createImagingCentreCharge(imagingCentreChargesCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Imagingcentre Charges Bulk
         * @param {Array<ImagingCentreChargesCreate>} imagingCentreChargesCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createImagingCentreChargesBulk(imagingCentreChargesCreate: Array<ImagingCentreChargesCreate>, options?: any): AxiosPromise<any> {
            return localVarFp.createImagingCentreChargesBulk(imagingCentreChargesCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Imagingcentre Charge
         * @param {number} chargeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImagingCentreCharge(chargeId: number, options?: any): AxiosPromise<any> {
            return localVarFp.deleteImagingCentreCharge(chargeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Imagingcentre Charges
         * @param {number} imagingCentreId 
         * @param {string} modality 
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImagingCentreCharges(imagingCentreId: number, modality: string, sort?: string, range?: string, filter?: string, options?: any): AxiosPromise<Array<ImagingCentreCharges>> {
            return localVarFp.getImagingCentreCharges(imagingCentreId, modality, sort, range, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Imagingcentre Charge
         * @param {number} chargeId 
         * @param {ImagingCentreChargesUpdate} imagingCentreChargesUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateImagingCentreCharge(chargeId: number, imagingCentreChargesUpdate: ImagingCentreChargesUpdate, options?: any): AxiosPromise<ImagingCentreCharges> {
            return localVarFp.updateImagingCentreCharge(chargeId, imagingCentreChargesUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for cloneImagingCentreCharge operation in ImagingCentreChargesApi.
 * @export
 * @interface ImagingCentreChargesApiCloneImagingCentreChargeRequest
 */
export interface ImagingCentreChargesApiCloneImagingCentreChargeRequest {
    /**
     * 
     * @type {number}
     * @memberof ImagingCentreChargesApiCloneImagingCentreCharge
     */
    readonly centreIdFrom: number

    /**
     * 
     * @type {number}
     * @memberof ImagingCentreChargesApiCloneImagingCentreCharge
     */
    readonly centreIdTo: number
}

/**
 * Request parameters for createImagingCentreCharge operation in ImagingCentreChargesApi.
 * @export
 * @interface ImagingCentreChargesApiCreateImagingCentreChargeRequest
 */
export interface ImagingCentreChargesApiCreateImagingCentreChargeRequest {
    /**
     * 
     * @type {ImagingCentreChargesCreate}
     * @memberof ImagingCentreChargesApiCreateImagingCentreCharge
     */
    readonly imagingCentreChargesCreate: ImagingCentreChargesCreate
}

/**
 * Request parameters for createImagingCentreChargesBulk operation in ImagingCentreChargesApi.
 * @export
 * @interface ImagingCentreChargesApiCreateImagingCentreChargesBulkRequest
 */
export interface ImagingCentreChargesApiCreateImagingCentreChargesBulkRequest {
    /**
     * 
     * @type {Array<ImagingCentreChargesCreate>}
     * @memberof ImagingCentreChargesApiCreateImagingCentreChargesBulk
     */
    readonly imagingCentreChargesCreate: Array<ImagingCentreChargesCreate>
}

/**
 * Request parameters for deleteImagingCentreCharge operation in ImagingCentreChargesApi.
 * @export
 * @interface ImagingCentreChargesApiDeleteImagingCentreChargeRequest
 */
export interface ImagingCentreChargesApiDeleteImagingCentreChargeRequest {
    /**
     * 
     * @type {number}
     * @memberof ImagingCentreChargesApiDeleteImagingCentreCharge
     */
    readonly chargeId: number
}

/**
 * Request parameters for getImagingCentreCharges operation in ImagingCentreChargesApi.
 * @export
 * @interface ImagingCentreChargesApiGetImagingCentreChargesRequest
 */
export interface ImagingCentreChargesApiGetImagingCentreChargesRequest {
    /**
     * 
     * @type {number}
     * @memberof ImagingCentreChargesApiGetImagingCentreCharges
     */
    readonly imagingCentreId: number

    /**
     * 
     * @type {string}
     * @memberof ImagingCentreChargesApiGetImagingCentreCharges
     */
    readonly modality: string

    /**
     * Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
     * @type {string}
     * @memberof ImagingCentreChargesApiGetImagingCentreCharges
     */
    readonly sort?: string

    /**
     * Format: &#x60;[start, end]&#x60;
     * @type {string}
     * @memberof ImagingCentreChargesApiGetImagingCentreCharges
     */
    readonly range?: string

    /**
     * Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
     * @type {string}
     * @memberof ImagingCentreChargesApiGetImagingCentreCharges
     */
    readonly filter?: string
}

/**
 * Request parameters for updateImagingCentreCharge operation in ImagingCentreChargesApi.
 * @export
 * @interface ImagingCentreChargesApiUpdateImagingCentreChargeRequest
 */
export interface ImagingCentreChargesApiUpdateImagingCentreChargeRequest {
    /**
     * 
     * @type {number}
     * @memberof ImagingCentreChargesApiUpdateImagingCentreCharge
     */
    readonly chargeId: number

    /**
     * 
     * @type {ImagingCentreChargesUpdate}
     * @memberof ImagingCentreChargesApiUpdateImagingCentreCharge
     */
    readonly imagingCentreChargesUpdate: ImagingCentreChargesUpdate
}

/**
 * ImagingCentreChargesApi - object-oriented interface
 * @export
 * @class ImagingCentreChargesApi
 * @extends {BaseAPI}
 */
export class ImagingCentreChargesApi extends BaseAPI {
    /**
     * 
     * @summary Clone Imaging Centre Charge
     * @param {ImagingCentreChargesApiCloneImagingCentreChargeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagingCentreChargesApi
     */
    public cloneImagingCentreCharge(requestParameters: ImagingCentreChargesApiCloneImagingCentreChargeRequest, options?: AxiosRequestConfig) {
        return ImagingCentreChargesApiFp(this.configuration).cloneImagingCentreCharge(requestParameters.centreIdFrom, requestParameters.centreIdTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Imagingcentre Charge
     * @param {ImagingCentreChargesApiCreateImagingCentreChargeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagingCentreChargesApi
     */
    public createImagingCentreCharge(requestParameters: ImagingCentreChargesApiCreateImagingCentreChargeRequest, options?: AxiosRequestConfig) {
        return ImagingCentreChargesApiFp(this.configuration).createImagingCentreCharge(requestParameters.imagingCentreChargesCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Imagingcentre Charges Bulk
     * @param {ImagingCentreChargesApiCreateImagingCentreChargesBulkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagingCentreChargesApi
     */
    public createImagingCentreChargesBulk(requestParameters: ImagingCentreChargesApiCreateImagingCentreChargesBulkRequest, options?: AxiosRequestConfig) {
        return ImagingCentreChargesApiFp(this.configuration).createImagingCentreChargesBulk(requestParameters.imagingCentreChargesCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Imagingcentre Charge
     * @param {ImagingCentreChargesApiDeleteImagingCentreChargeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagingCentreChargesApi
     */
    public deleteImagingCentreCharge(requestParameters: ImagingCentreChargesApiDeleteImagingCentreChargeRequest, options?: AxiosRequestConfig) {
        return ImagingCentreChargesApiFp(this.configuration).deleteImagingCentreCharge(requestParameters.chargeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Imagingcentre Charges
     * @param {ImagingCentreChargesApiGetImagingCentreChargesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagingCentreChargesApi
     */
    public getImagingCentreCharges(requestParameters: ImagingCentreChargesApiGetImagingCentreChargesRequest, options?: AxiosRequestConfig) {
        return ImagingCentreChargesApiFp(this.configuration).getImagingCentreCharges(requestParameters.imagingCentreId, requestParameters.modality, requestParameters.sort, requestParameters.range, requestParameters.filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Imagingcentre Charge
     * @param {ImagingCentreChargesApiUpdateImagingCentreChargeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagingCentreChargesApi
     */
    public updateImagingCentreCharge(requestParameters: ImagingCentreChargesApiUpdateImagingCentreChargeRequest, options?: AxiosRequestConfig) {
        return ImagingCentreChargesApiFp(this.configuration).updateImagingCentreCharge(requestParameters.chargeId, requestParameters.imagingCentreChargesUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}

// third-party --------------------------------------
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// project imports ----------------------------------
// import axios from 'utils/axios';
// import { dispatch } from '../index';
import { addAsyncThunk } from '../helper';

// Auto Generated openAPI --------------------------------
import { radiologistsApi } from 'providers/env'
import { RadiologistGet as Radiologist, Radiologist as RadiologistList } from 'generated/models'
import { RadiologistsApiGetRadiologistsRequest, RadiologistsApiCreateRadiologistRequest, RadiologistsApiUpdateRadiologistRequest } from '../../generated/api/radiologists-api';
// --------------------------------------------------

type RadiologistState = {
  radiologist: Radiologist | null;
  radiologists: { "list": RadiologistList[], totalRecords: number };
  status: 'idle' | 'loading' | 'failed';
  error: string | null;
}

const initialState: RadiologistState = {
  radiologist: null,
  radiologists: { "list": [], totalRecords: 0 },
  status: 'idle',
  error: null
};


// code for async thunks and the slice... 
// Async thunks --------------------------------------
export const getRadiologists = createAsyncThunk('radiologists/getStatus',
  async (requestParameters: RadiologistsApiGetRadiologistsRequest = {}) => {
    const response = await radiologistsApi.getRadiologists(requestParameters);
    return { "list": response.data, totalRecords: response.headers["content-range"].split('/')[1] };
  });

export const createRadiologist = createAsyncThunk('radiologists/createStatus',
  async (itemData: RadiologistsApiCreateRadiologistRequest) => {
    const response = await radiologistsApi.createRadiologist(itemData)
    return response.data
  }
);

export const deleteRadiologist = createAsyncThunk('radiologists/deleteStatus',
  async (radiologistId: number) => {
    const response = await radiologistsApi.deleteRadiologist({ radiologistId });
    return response.data;
  }
);

export const getRadiologist = createAsyncThunk('radiologists/getSingleStatus',
  async (radiologistId: number) => {
    const response = await radiologistsApi.getRadiologist({ radiologistId });
    return response.data;
  }
);

export const updateRadiologist = createAsyncThunk('radiologists/updateStatus',
  async (updateData: RadiologistsApiUpdateRadiologistRequest) => {
    const response = await radiologistsApi.updateRadiologist(updateData);
    return response.data;
  }
);

const radiologistSlice = createSlice({
  name: 'radiologist',
  initialState,
  reducers: {
    // Any synchronous actions would go here
  },
  extraReducers: (builder) => {
    // the helper function to add the cases for each async thunk
    addAsyncThunk(builder, getRadiologists, 'radiologists');
    addAsyncThunk(builder, createRadiologist, 'radiologist');
    addAsyncThunk(builder, deleteRadiologist, 'deleteStatus');
    addAsyncThunk(builder, getRadiologist, 'getSingleStatus');
    addAsyncThunk(builder, updateRadiologist, 'updateStatus');
  },
});

export default radiologistSlice.reducer;

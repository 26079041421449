/* tslint:disable */
/* eslint-disable */
/**
 * eminence-pac-system
 * eminence-pac-system API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { HTTPValidationError } from '../models';
// @ts-ignore
import { RadiologistCharges } from '../models';
// @ts-ignore
import { RadiologistChargesCreate } from '../models';
// @ts-ignore
import { RadiologistChargesUpdate } from '../models';
/**
 * RadiologistChargesApi - axios parameter creator
 * @export
 */
export const RadiologistChargesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Radiologist Charge
         * @param {RadiologistChargesCreate} radiologistChargesCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRadiologistCharge: async (radiologistChargesCreate: RadiologistChargesCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'radiologistChargesCreate' is not null or undefined
            assertParamExists('createRadiologistCharge', 'radiologistChargesCreate', radiologistChargesCreate)
            const localVarPath = `/api/v1/radiologist/charges`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(radiologistChargesCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Radiologist Charges Bulk
         * @param {Array<RadiologistChargesCreate>} radiologistChargesCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRadiologistChargesBulk: async (radiologistChargesCreate: Array<RadiologistChargesCreate>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'radiologistChargesCreate' is not null or undefined
            assertParamExists('createRadiologistChargesBulk', 'radiologistChargesCreate', radiologistChargesCreate)
            const localVarPath = `/api/v1/radiologist/charges/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(radiologistChargesCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Radiologist Charge
         * @param {number} chargeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRadiologistCharge: async (chargeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chargeId' is not null or undefined
            assertParamExists('deleteRadiologistCharge', 'chargeId', chargeId)
            const localVarPath = `/api/v1/radiologist/charges/{charge_id}`
                .replace(`{${"charge_id"}}`, encodeURIComponent(String(chargeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Radiologist Charges
         * @param {number} radiologistId 
         * @param {string} modality 
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRadiologistCharges: async (radiologistId: number, modality: string, sort?: string, range?: string, filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'radiologistId' is not null or undefined
            assertParamExists('getRadiologistCharges', 'radiologistId', radiologistId)
            // verify required parameter 'modality' is not null or undefined
            assertParamExists('getRadiologistCharges', 'modality', modality)
            const localVarPath = `/api/v1/radiologist/{radiologist_id}/charges`
                .replace(`{${"radiologist_id"}}`, encodeURIComponent(String(radiologistId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (modality !== undefined) {
                localVarQueryParameter['modality'] = modality;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (range !== undefined) {
                localVarQueryParameter['range'] = range;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Radiologist Charge
         * @param {number} chargeId 
         * @param {RadiologistChargesUpdate} radiologistChargesUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRadiologistCharge: async (chargeId: number, radiologistChargesUpdate: RadiologistChargesUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chargeId' is not null or undefined
            assertParamExists('updateRadiologistCharge', 'chargeId', chargeId)
            // verify required parameter 'radiologistChargesUpdate' is not null or undefined
            assertParamExists('updateRadiologistCharge', 'radiologistChargesUpdate', radiologistChargesUpdate)
            const localVarPath = `/api/v1/radiologist/charges/{charge_id}`
                .replace(`{${"charge_id"}}`, encodeURIComponent(String(chargeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(radiologistChargesUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RadiologistChargesApi - functional programming interface
 * @export
 */
export const RadiologistChargesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RadiologistChargesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Radiologist Charge
         * @param {RadiologistChargesCreate} radiologistChargesCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRadiologistCharge(radiologistChargesCreate: RadiologistChargesCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RadiologistCharges>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRadiologistCharge(radiologistChargesCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Radiologist Charges Bulk
         * @param {Array<RadiologistChargesCreate>} radiologistChargesCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRadiologistChargesBulk(radiologistChargesCreate: Array<RadiologistChargesCreate>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRadiologistChargesBulk(radiologistChargesCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Radiologist Charge
         * @param {number} chargeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRadiologistCharge(chargeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRadiologistCharge(chargeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Radiologist Charges
         * @param {number} radiologistId 
         * @param {string} modality 
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRadiologistCharges(radiologistId: number, modality: string, sort?: string, range?: string, filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RadiologistCharges>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRadiologistCharges(radiologistId, modality, sort, range, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Radiologist Charge
         * @param {number} chargeId 
         * @param {RadiologistChargesUpdate} radiologistChargesUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRadiologistCharge(chargeId: number, radiologistChargesUpdate: RadiologistChargesUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RadiologistCharges>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRadiologistCharge(chargeId, radiologistChargesUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RadiologistChargesApi - factory interface
 * @export
 */
export const RadiologistChargesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RadiologistChargesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Radiologist Charge
         * @param {RadiologistChargesCreate} radiologistChargesCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRadiologistCharge(radiologistChargesCreate: RadiologistChargesCreate, options?: any): AxiosPromise<RadiologistCharges> {
            return localVarFp.createRadiologistCharge(radiologistChargesCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Radiologist Charges Bulk
         * @param {Array<RadiologistChargesCreate>} radiologistChargesCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRadiologistChargesBulk(radiologistChargesCreate: Array<RadiologistChargesCreate>, options?: any): AxiosPromise<any> {
            return localVarFp.createRadiologistChargesBulk(radiologistChargesCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Radiologist Charge
         * @param {number} chargeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRadiologistCharge(chargeId: number, options?: any): AxiosPromise<any> {
            return localVarFp.deleteRadiologistCharge(chargeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Radiologist Charges
         * @param {number} radiologistId 
         * @param {string} modality 
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRadiologistCharges(radiologistId: number, modality: string, sort?: string, range?: string, filter?: string, options?: any): AxiosPromise<Array<RadiologistCharges>> {
            return localVarFp.getRadiologistCharges(radiologistId, modality, sort, range, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Radiologist Charge
         * @param {number} chargeId 
         * @param {RadiologistChargesUpdate} radiologistChargesUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRadiologistCharge(chargeId: number, radiologistChargesUpdate: RadiologistChargesUpdate, options?: any): AxiosPromise<RadiologistCharges> {
            return localVarFp.updateRadiologistCharge(chargeId, radiologistChargesUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createRadiologistCharge operation in RadiologistChargesApi.
 * @export
 * @interface RadiologistChargesApiCreateRadiologistChargeRequest
 */
export interface RadiologistChargesApiCreateRadiologistChargeRequest {
    /**
     * 
     * @type {RadiologistChargesCreate}
     * @memberof RadiologistChargesApiCreateRadiologistCharge
     */
    readonly radiologistChargesCreate: RadiologistChargesCreate
}

/**
 * Request parameters for createRadiologistChargesBulk operation in RadiologistChargesApi.
 * @export
 * @interface RadiologistChargesApiCreateRadiologistChargesBulkRequest
 */
export interface RadiologistChargesApiCreateRadiologistChargesBulkRequest {
    /**
     * 
     * @type {Array<RadiologistChargesCreate>}
     * @memberof RadiologistChargesApiCreateRadiologistChargesBulk
     */
    readonly radiologistChargesCreate: Array<RadiologistChargesCreate>
}

/**
 * Request parameters for deleteRadiologistCharge operation in RadiologistChargesApi.
 * @export
 * @interface RadiologistChargesApiDeleteRadiologistChargeRequest
 */
export interface RadiologistChargesApiDeleteRadiologistChargeRequest {
    /**
     * 
     * @type {number}
     * @memberof RadiologistChargesApiDeleteRadiologistCharge
     */
    readonly chargeId: number
}

/**
 * Request parameters for getRadiologistCharges operation in RadiologistChargesApi.
 * @export
 * @interface RadiologistChargesApiGetRadiologistChargesRequest
 */
export interface RadiologistChargesApiGetRadiologistChargesRequest {
    /**
     * 
     * @type {number}
     * @memberof RadiologistChargesApiGetRadiologistCharges
     */
    readonly radiologistId: number

    /**
     * 
     * @type {string}
     * @memberof RadiologistChargesApiGetRadiologistCharges
     */
    readonly modality: string

    /**
     * Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
     * @type {string}
     * @memberof RadiologistChargesApiGetRadiologistCharges
     */
    readonly sort?: string

    /**
     * Format: &#x60;[start, end]&#x60;
     * @type {string}
     * @memberof RadiologistChargesApiGetRadiologistCharges
     */
    readonly range?: string

    /**
     * Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
     * @type {string}
     * @memberof RadiologistChargesApiGetRadiologistCharges
     */
    readonly filter?: string
}

/**
 * Request parameters for updateRadiologistCharge operation in RadiologistChargesApi.
 * @export
 * @interface RadiologistChargesApiUpdateRadiologistChargeRequest
 */
export interface RadiologistChargesApiUpdateRadiologistChargeRequest {
    /**
     * 
     * @type {number}
     * @memberof RadiologistChargesApiUpdateRadiologistCharge
     */
    readonly chargeId: number

    /**
     * 
     * @type {RadiologistChargesUpdate}
     * @memberof RadiologistChargesApiUpdateRadiologistCharge
     */
    readonly radiologistChargesUpdate: RadiologistChargesUpdate
}

/**
 * RadiologistChargesApi - object-oriented interface
 * @export
 * @class RadiologistChargesApi
 * @extends {BaseAPI}
 */
export class RadiologistChargesApi extends BaseAPI {
    /**
     * 
     * @summary Create Radiologist Charge
     * @param {RadiologistChargesApiCreateRadiologistChargeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RadiologistChargesApi
     */
    public createRadiologistCharge(requestParameters: RadiologistChargesApiCreateRadiologistChargeRequest, options?: AxiosRequestConfig) {
        return RadiologistChargesApiFp(this.configuration).createRadiologistCharge(requestParameters.radiologistChargesCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Radiologist Charges Bulk
     * @param {RadiologistChargesApiCreateRadiologistChargesBulkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RadiologistChargesApi
     */
    public createRadiologistChargesBulk(requestParameters: RadiologistChargesApiCreateRadiologistChargesBulkRequest, options?: AxiosRequestConfig) {
        return RadiologistChargesApiFp(this.configuration).createRadiologistChargesBulk(requestParameters.radiologistChargesCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Radiologist Charge
     * @param {RadiologistChargesApiDeleteRadiologistChargeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RadiologistChargesApi
     */
    public deleteRadiologistCharge(requestParameters: RadiologistChargesApiDeleteRadiologistChargeRequest, options?: AxiosRequestConfig) {
        return RadiologistChargesApiFp(this.configuration).deleteRadiologistCharge(requestParameters.chargeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Radiologist Charges
     * @param {RadiologistChargesApiGetRadiologistChargesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RadiologistChargesApi
     */
    public getRadiologistCharges(requestParameters: RadiologistChargesApiGetRadiologistChargesRequest, options?: AxiosRequestConfig) {
        return RadiologistChargesApiFp(this.configuration).getRadiologistCharges(requestParameters.radiologistId, requestParameters.modality, requestParameters.sort, requestParameters.range, requestParameters.filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Radiologist Charge
     * @param {RadiologistChargesApiUpdateRadiologistChargeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RadiologistChargesApi
     */
    public updateRadiologistCharge(requestParameters: RadiologistChargesApiUpdateRadiologistChargeRequest, options?: AxiosRequestConfig) {
        return RadiologistChargesApiFp(this.configuration).updateRadiologistCharge(requestParameters.chargeId, requestParameters.radiologistChargesUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}

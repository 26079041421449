import { AuthApi, Configuration, UsersApi, BodyPartsApi, ImagingCentresApi, ImagingCentreChargesApi, ImagingCentreGradeChargesApi, ImagingCentreExtraChargesApi, RadiologistsApi, RadiologistChargesApi, RadiologistGradeChargesApi, RadiologistExtraChargesApi, PatientsApi, StudyApi, InvoiceApi, InvoiceAdditionalChargesApi, BillingApi, UtilsApi } from "../generated";

const readApiBaseFromEnv = (): string => {
  // Get API base URL from env
  // Priority is given to same host in the browser when environemnt is production
  if (
    process.env.NODE_ENV === "production" &&
    !document.location.host.startsWith("localhost")
  ) {
    return `https://${document.location.host}`;
  } else if (process.env.REACT_APP_API_BASE) {
    return process.env.REACT_APP_API_BASE;
  }
  return "http://localhost:8000";
};

const readAccessToken = async (): Promise<string> => {
  // return localStorage.getItem("token") || "";
  return localStorage.getItem("serviceToken") || "";
};

export const basePath: string = readApiBaseFromEnv();

const apiConfig: Configuration = new Configuration({
  basePath,
  accessToken: readAccessToken,
});

export const authApi: AuthApi = new AuthApi(apiConfig);
export const userApi: UsersApi = new UsersApi(apiConfig);
export const bodyPartsApi: BodyPartsApi = new BodyPartsApi(apiConfig);
export const imagingCentresApi: ImagingCentresApi = new ImagingCentresApi(apiConfig);
export const imagingCentreChargesApi: ImagingCentreChargesApi = new ImagingCentreChargesApi(apiConfig);
export const imagingCentreGradeChargesApi: ImagingCentreGradeChargesApi = new ImagingCentreGradeChargesApi(apiConfig);
export const imagingCentreExtraChargesApi: ImagingCentreExtraChargesApi = new ImagingCentreExtraChargesApi(apiConfig);
export const radiologistsApi: RadiologistsApi = new RadiologistsApi(apiConfig);
export const radiologistChargesApi: RadiologistChargesApi = new RadiologistChargesApi(apiConfig);
export const radiologistGradeChargesApi: RadiologistGradeChargesApi = new RadiologistGradeChargesApi(apiConfig);
export const radiologistExtraChargesApi: RadiologistExtraChargesApi = new RadiologistExtraChargesApi(apiConfig);
export const patientsApi: PatientsApi = new PatientsApi(apiConfig);
export const studyApi: StudyApi = new StudyApi(apiConfig);
export const invoiceApi: InvoiceApi = new InvoiceApi(apiConfig);
export const invoiceAdditionalChargesApi: InvoiceAdditionalChargesApi = new InvoiceAdditionalChargesApi(apiConfig);
export const billingApi: BillingApi = new BillingApi(apiConfig);
export const utilsApi: UtilsApi = new UtilsApi(apiConfig);



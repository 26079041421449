/* tslint:disable */
/* eslint-disable */
/**
 * eminence-pac-system
 * eminence-pac-system API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { HTTPValidationError } from '../models';
// @ts-ignore
import { ImagingCentreGradeCharges } from '../models';
// @ts-ignore
import { ImagingCentreGradeChargesCreate } from '../models';
// @ts-ignore
import { ImagingCentreGradeChargesUpdate } from '../models';
/**
 * ImagingCentreGradeChargesApi - axios parameter creator
 * @export
 */
export const ImagingCentreGradeChargesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Centre Grade Charge
         * @param {ImagingCentreGradeChargesCreate} imagingCentreGradeChargesCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCentreGradeCharge: async (imagingCentreGradeChargesCreate: ImagingCentreGradeChargesCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'imagingCentreGradeChargesCreate' is not null or undefined
            assertParamExists('createCentreGradeCharge', 'imagingCentreGradeChargesCreate', imagingCentreGradeChargesCreate)
            const localVarPath = `/api/v1/imaging_centre/grade/charges`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(imagingCentreGradeChargesCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Centre Grade Charges Bulk
         * @param {Array<ImagingCentreGradeChargesCreate>} imagingCentreGradeChargesCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCentreGradeChargesBulk: async (imagingCentreGradeChargesCreate: Array<ImagingCentreGradeChargesCreate>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'imagingCentreGradeChargesCreate' is not null or undefined
            assertParamExists('createCentreGradeChargesBulk', 'imagingCentreGradeChargesCreate', imagingCentreGradeChargesCreate)
            const localVarPath = `/api/v1/imaging_centre/grade/charges/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(imagingCentreGradeChargesCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Centre Grade Charge
         * @param {number} chargeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCentreGradeCharge: async (chargeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chargeId' is not null or undefined
            assertParamExists('deleteCentreGradeCharge', 'chargeId', chargeId)
            const localVarPath = `/api/v1/imaging_centre/grade/charges/{charge_id}`
                .replace(`{${"charge_id"}}`, encodeURIComponent(String(chargeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Centre Grade Charges
         * @param {number} gradeId 
         * @param {string} modality 
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCentreGradeCharges: async (gradeId: number, modality: string, sort?: string, range?: string, filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gradeId' is not null or undefined
            assertParamExists('getCentreGradeCharges', 'gradeId', gradeId)
            // verify required parameter 'modality' is not null or undefined
            assertParamExists('getCentreGradeCharges', 'modality', modality)
            const localVarPath = `/api/v1/imaging_centre/grade/{grade_id}/charges`
                .replace(`{${"grade_id"}}`, encodeURIComponent(String(gradeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (modality !== undefined) {
                localVarQueryParameter['modality'] = modality;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (range !== undefined) {
                localVarQueryParameter['range'] = range;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Centre Grade Charge
         * @param {number} chargeId 
         * @param {ImagingCentreGradeChargesUpdate} imagingCentreGradeChargesUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCentreGradeCharge: async (chargeId: number, imagingCentreGradeChargesUpdate: ImagingCentreGradeChargesUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chargeId' is not null or undefined
            assertParamExists('updateCentreGradeCharge', 'chargeId', chargeId)
            // verify required parameter 'imagingCentreGradeChargesUpdate' is not null or undefined
            assertParamExists('updateCentreGradeCharge', 'imagingCentreGradeChargesUpdate', imagingCentreGradeChargesUpdate)
            const localVarPath = `/api/v1/imaging_centre/grade/charges/{charge_id}`
                .replace(`{${"charge_id"}}`, encodeURIComponent(String(chargeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(imagingCentreGradeChargesUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ImagingCentreGradeChargesApi - functional programming interface
 * @export
 */
export const ImagingCentreGradeChargesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ImagingCentreGradeChargesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Centre Grade Charge
         * @param {ImagingCentreGradeChargesCreate} imagingCentreGradeChargesCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCentreGradeCharge(imagingCentreGradeChargesCreate: ImagingCentreGradeChargesCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImagingCentreGradeCharges>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCentreGradeCharge(imagingCentreGradeChargesCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Centre Grade Charges Bulk
         * @param {Array<ImagingCentreGradeChargesCreate>} imagingCentreGradeChargesCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCentreGradeChargesBulk(imagingCentreGradeChargesCreate: Array<ImagingCentreGradeChargesCreate>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCentreGradeChargesBulk(imagingCentreGradeChargesCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Centre Grade Charge
         * @param {number} chargeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCentreGradeCharge(chargeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCentreGradeCharge(chargeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Centre Grade Charges
         * @param {number} gradeId 
         * @param {string} modality 
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCentreGradeCharges(gradeId: number, modality: string, sort?: string, range?: string, filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ImagingCentreGradeCharges>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCentreGradeCharges(gradeId, modality, sort, range, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Centre Grade Charge
         * @param {number} chargeId 
         * @param {ImagingCentreGradeChargesUpdate} imagingCentreGradeChargesUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCentreGradeCharge(chargeId: number, imagingCentreGradeChargesUpdate: ImagingCentreGradeChargesUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImagingCentreGradeCharges>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCentreGradeCharge(chargeId, imagingCentreGradeChargesUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ImagingCentreGradeChargesApi - factory interface
 * @export
 */
export const ImagingCentreGradeChargesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ImagingCentreGradeChargesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Centre Grade Charge
         * @param {ImagingCentreGradeChargesCreate} imagingCentreGradeChargesCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCentreGradeCharge(imagingCentreGradeChargesCreate: ImagingCentreGradeChargesCreate, options?: any): AxiosPromise<ImagingCentreGradeCharges> {
            return localVarFp.createCentreGradeCharge(imagingCentreGradeChargesCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Centre Grade Charges Bulk
         * @param {Array<ImagingCentreGradeChargesCreate>} imagingCentreGradeChargesCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCentreGradeChargesBulk(imagingCentreGradeChargesCreate: Array<ImagingCentreGradeChargesCreate>, options?: any): AxiosPromise<any> {
            return localVarFp.createCentreGradeChargesBulk(imagingCentreGradeChargesCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Centre Grade Charge
         * @param {number} chargeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCentreGradeCharge(chargeId: number, options?: any): AxiosPromise<any> {
            return localVarFp.deleteCentreGradeCharge(chargeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Centre Grade Charges
         * @param {number} gradeId 
         * @param {string} modality 
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCentreGradeCharges(gradeId: number, modality: string, sort?: string, range?: string, filter?: string, options?: any): AxiosPromise<Array<ImagingCentreGradeCharges>> {
            return localVarFp.getCentreGradeCharges(gradeId, modality, sort, range, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Centre Grade Charge
         * @param {number} chargeId 
         * @param {ImagingCentreGradeChargesUpdate} imagingCentreGradeChargesUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCentreGradeCharge(chargeId: number, imagingCentreGradeChargesUpdate: ImagingCentreGradeChargesUpdate, options?: any): AxiosPromise<ImagingCentreGradeCharges> {
            return localVarFp.updateCentreGradeCharge(chargeId, imagingCentreGradeChargesUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createCentreGradeCharge operation in ImagingCentreGradeChargesApi.
 * @export
 * @interface ImagingCentreGradeChargesApiCreateCentreGradeChargeRequest
 */
export interface ImagingCentreGradeChargesApiCreateCentreGradeChargeRequest {
    /**
     * 
     * @type {ImagingCentreGradeChargesCreate}
     * @memberof ImagingCentreGradeChargesApiCreateCentreGradeCharge
     */
    readonly imagingCentreGradeChargesCreate: ImagingCentreGradeChargesCreate
}

/**
 * Request parameters for createCentreGradeChargesBulk operation in ImagingCentreGradeChargesApi.
 * @export
 * @interface ImagingCentreGradeChargesApiCreateCentreGradeChargesBulkRequest
 */
export interface ImagingCentreGradeChargesApiCreateCentreGradeChargesBulkRequest {
    /**
     * 
     * @type {Array<ImagingCentreGradeChargesCreate>}
     * @memberof ImagingCentreGradeChargesApiCreateCentreGradeChargesBulk
     */
    readonly imagingCentreGradeChargesCreate: Array<ImagingCentreGradeChargesCreate>
}

/**
 * Request parameters for deleteCentreGradeCharge operation in ImagingCentreGradeChargesApi.
 * @export
 * @interface ImagingCentreGradeChargesApiDeleteCentreGradeChargeRequest
 */
export interface ImagingCentreGradeChargesApiDeleteCentreGradeChargeRequest {
    /**
     * 
     * @type {number}
     * @memberof ImagingCentreGradeChargesApiDeleteCentreGradeCharge
     */
    readonly chargeId: number
}

/**
 * Request parameters for getCentreGradeCharges operation in ImagingCentreGradeChargesApi.
 * @export
 * @interface ImagingCentreGradeChargesApiGetCentreGradeChargesRequest
 */
export interface ImagingCentreGradeChargesApiGetCentreGradeChargesRequest {
    /**
     * 
     * @type {number}
     * @memberof ImagingCentreGradeChargesApiGetCentreGradeCharges
     */
    readonly gradeId: number

    /**
     * 
     * @type {string}
     * @memberof ImagingCentreGradeChargesApiGetCentreGradeCharges
     */
    readonly modality: string

    /**
     * Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
     * @type {string}
     * @memberof ImagingCentreGradeChargesApiGetCentreGradeCharges
     */
    readonly sort?: string

    /**
     * Format: &#x60;[start, end]&#x60;
     * @type {string}
     * @memberof ImagingCentreGradeChargesApiGetCentreGradeCharges
     */
    readonly range?: string

    /**
     * Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
     * @type {string}
     * @memberof ImagingCentreGradeChargesApiGetCentreGradeCharges
     */
    readonly filter?: string
}

/**
 * Request parameters for updateCentreGradeCharge operation in ImagingCentreGradeChargesApi.
 * @export
 * @interface ImagingCentreGradeChargesApiUpdateCentreGradeChargeRequest
 */
export interface ImagingCentreGradeChargesApiUpdateCentreGradeChargeRequest {
    /**
     * 
     * @type {number}
     * @memberof ImagingCentreGradeChargesApiUpdateCentreGradeCharge
     */
    readonly chargeId: number

    /**
     * 
     * @type {ImagingCentreGradeChargesUpdate}
     * @memberof ImagingCentreGradeChargesApiUpdateCentreGradeCharge
     */
    readonly imagingCentreGradeChargesUpdate: ImagingCentreGradeChargesUpdate
}

/**
 * ImagingCentreGradeChargesApi - object-oriented interface
 * @export
 * @class ImagingCentreGradeChargesApi
 * @extends {BaseAPI}
 */
export class ImagingCentreGradeChargesApi extends BaseAPI {
    /**
     * 
     * @summary Create Centre Grade Charge
     * @param {ImagingCentreGradeChargesApiCreateCentreGradeChargeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagingCentreGradeChargesApi
     */
    public createCentreGradeCharge(requestParameters: ImagingCentreGradeChargesApiCreateCentreGradeChargeRequest, options?: AxiosRequestConfig) {
        return ImagingCentreGradeChargesApiFp(this.configuration).createCentreGradeCharge(requestParameters.imagingCentreGradeChargesCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Centre Grade Charges Bulk
     * @param {ImagingCentreGradeChargesApiCreateCentreGradeChargesBulkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagingCentreGradeChargesApi
     */
    public createCentreGradeChargesBulk(requestParameters: ImagingCentreGradeChargesApiCreateCentreGradeChargesBulkRequest, options?: AxiosRequestConfig) {
        return ImagingCentreGradeChargesApiFp(this.configuration).createCentreGradeChargesBulk(requestParameters.imagingCentreGradeChargesCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Centre Grade Charge
     * @param {ImagingCentreGradeChargesApiDeleteCentreGradeChargeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagingCentreGradeChargesApi
     */
    public deleteCentreGradeCharge(requestParameters: ImagingCentreGradeChargesApiDeleteCentreGradeChargeRequest, options?: AxiosRequestConfig) {
        return ImagingCentreGradeChargesApiFp(this.configuration).deleteCentreGradeCharge(requestParameters.chargeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Centre Grade Charges
     * @param {ImagingCentreGradeChargesApiGetCentreGradeChargesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagingCentreGradeChargesApi
     */
    public getCentreGradeCharges(requestParameters: ImagingCentreGradeChargesApiGetCentreGradeChargesRequest, options?: AxiosRequestConfig) {
        return ImagingCentreGradeChargesApiFp(this.configuration).getCentreGradeCharges(requestParameters.gradeId, requestParameters.modality, requestParameters.sort, requestParameters.range, requestParameters.filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Centre Grade Charge
     * @param {ImagingCentreGradeChargesApiUpdateCentreGradeChargeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagingCentreGradeChargesApi
     */
    public updateCentreGradeCharge(requestParameters: ImagingCentreGradeChargesApiUpdateCentreGradeChargeRequest, options?: AxiosRequestConfig) {
        return ImagingCentreGradeChargesApiFp(this.configuration).updateCentreGradeCharge(requestParameters.chargeId, requestParameters.imagingCentreGradeChargesUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}

import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const HomeDefault = Loadable(lazy(() => import('pages/apps/index')));

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard/default')));
const DashboardAnalytics = Loadable(lazy(() => import('pages/dashboard/analytics')));  

const AppBodypartList = Loadable(lazy(() => import('pages/apps/bodypart/list')));

// const AppImagingCentreList = Loadable(lazy(() => import('pages/apps/imaging-centre/list'))); //dispatchEvent
const AppImagingCentreCard = Loadable(lazy(() => import('pages/apps/imaging-centre/card')));
const AppImagingCentreGradeCharges = Loadable(lazy(() => import('pages/apps/imaging-centre/grade-charges')));
const AppImagingCentreCharges = Loadable(lazy(() => import('pages/apps/imaging-centre/charges')));
const AppImagingCentreExtraCharges = Loadable(lazy(() => import('pages/apps/imaging-centre/extra-charges')));

const AppRadiologistCard = Loadable(lazy(() => import('pages/apps/radiologist/card')));
const AppRadiologistProfile = Loadable(lazy(() => import('pages/apps/radiologist/profiles')));
const AppRadiologistGradeCharges = Loadable(lazy(() => import('pages/apps/radiologist/grade-charges')));
const AppRadiologistCharges = Loadable(lazy(() => import('pages/apps/radiologist/charges')));
const AppRadiologistExtraCharges = Loadable(lazy(() => import('pages/apps/radiologist/extra-charges')));


const AppInvoiceCreate = Loadable(lazy(() => import('pages/apps/invoice/create')));
const AppInvoiceDashboard = Loadable(lazy(() => import('pages/apps/invoice/dashboard')));
const AppInvoiceList = Loadable(lazy(() => import('pages/apps/invoice/list')));
const AppInvoiceDetails = Loadable(lazy(() => import('pages/apps/invoice/details')));
const AppInvoiceEdit = Loadable(lazy(() => import('pages/apps/invoice/edit')));

const AppInvoiceCentreZeroCharges = Loadable(lazy(() => import('pages/apps/invoice/centre/study-zero-charges')));
const AppInvoiceRadiologistZeroCharges = Loadable(lazy(() => import('pages/apps/invoice/radiologist/study-zero-charges')));
// const AppInvoiceGenerate = Loadable(lazy(() => import('pages/apps/invoice/centre/generate-invoice')));
const AppInvoiceGenerate = Loadable(lazy(() => import('pages/apps/invoice/centre/list')));
const AppBillingGenerate = Loadable(lazy(() => import('pages/apps/invoice/radiologist/generate-billing')));

// Studies ----------------------------------------------------------------
const AppStudyNandico = Loadable(lazy(() => import('pages/apps/study/nandico')));
const AppStudyStradus = Loadable(lazy(() => import('pages/apps/study/stradus')));
const AppOperationStudies = Loadable(lazy(() => import('pages/apps/operations')));

const UserProfile = Loadable(lazy(() => import('pages/apps/profiles/user')));
const UserTabPersonal = Loadable(lazy(() => import('sections/apps/profiles/user/TabPersonal')));
const UserTabPayment = Loadable(lazy(() => import('sections/apps/profiles/user/TabPayment')));
const UserTabPassword = Loadable(lazy(() => import('sections/apps/profiles/user/TabPassword')));
const UserTabSettings = Loadable(lazy(() => import('sections/apps/profiles/user/TabSettings')));

const AccountProfile = Loadable(lazy(() => import('pages/apps/profiles/account')));
const AccountTabProfile = Loadable(lazy(() => import('sections/apps/profiles/account/TabProfile')));
const AccountTabPersonal = Loadable(lazy(() => import('sections/apps/profiles/account/TabPersonal')));
const AccountTabAccount = Loadable(lazy(() => import('sections/apps/profiles/account/TabAccount')));
const AccountTabPassword = Loadable(lazy(() => import('sections/apps/profiles/account/TabPassword')));
const AccountTabRole = Loadable(lazy(() => import('sections/apps/profiles/account/TabRole')));
const AccountTabSettings = Loadable(lazy(() => import('sections/apps/profiles/account/TabSettings')));
  
// pages routing
const AuthLogin = Loadable(lazy(() => import('pages/auth/login')));
const AuthForgotPassword = Loadable(lazy(() => import('pages/auth/forgot-password')));
const AuthResetPassword = Loadable(lazy(() => import('pages/auth/reset-password')));
const AuthCheckMail = Loadable(lazy(() => import('pages/auth/check-mail')));
const AuthCodeVerification = Loadable(lazy(() => import('pages/auth/code-verification')));

const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/404')));
const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/500')));
const MaintenanceUnderConstruction = Loadable(lazy(() => import('pages/maintenance/under-construction')));
 
 
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'home',
          element: <HomeDefault />             
        },
        // {
        //   path: 'dashboard',
        //   children: [
        //     {
        //       path: 'default',
        //       element: <DashboardDefault />
        //     },
        //     {
        //       path: 'admin',
        //       element: <DashboardAnalytics />
        //     }
        //   ]
        // }, 
        {
          path: 'apps',
          children: [ 
            {
              path: 'bodypart',
              children: [
                {
                  path: 'list',
                  element: <AppBodypartList />  
                } 
              ]
            },
            {
              path: 'imaging-centre',
              children: [
                {
                  path: 'list',
                  element: <AppImagingCentreCard /> //<AppImagingCentreList />
                },
                { 
                path: 'grade-charges',
                  element: <AppImagingCentreGradeCharges />
                },
                {
                  path: 'charges',
                  element: <AppImagingCentreCharges />
                },
                {
                  path: 'extra-charges',
                  element: <AppImagingCentreExtraCharges />
                }
              ]
            },
            {
              path: 'radiologist',
              children: [
                {
                  path: 'list',
                  element: <AppRadiologistCard /> 
                }, 
                {
                  path: 'grade-charges',
                  element: <AppRadiologistGradeCharges />
                }, 
                {
                  path: 'charges',
                  element: <AppRadiologistCharges />
                }, 
                {
                  path: 'extra-charges',
                  element: <AppRadiologistExtraCharges />
                },
                {
                  path: 'profiles',
                  element: <AppRadiologistProfile /> 
                }                 
              ]
            },
            {
              path: 'invoice',
              children: [
                {
                  path: 'dashboard',
                  element: <AppInvoiceDashboard />
                },


                {
                  path: 'centre/zero-charges',
                  element: <AppInvoiceCentreZeroCharges />
                },
                {
                  path: 'radiologist/zero-charges',
                  element: <AppInvoiceRadiologistZeroCharges />
                },

                {
                  path: 'centre/generate-invoice',
                  element: <AppInvoiceGenerate />
                },

                {
                  path: 'radiologist/generate-billing',
                  element: <AppBillingGenerate />
                },

                // {
                //   path: 'create',
                //   element: <AppInvoiceCreate />
                // },
                // {
                //   path: 'details/:id',
                //   element: <AppInvoiceDetails />
                // },
                // {
                //   path: 'edit/:id',
                //   element: <AppInvoiceEdit />
                // },
                // {
                //   path: 'list',
                //   element: <AppInvoiceList />
                // }
              ]
            },
            {
              path: 'study',
              children: [
                {
                  path: 'nandico',
                  element: <AppStudyNandico />
                },
                {
                  path: 'stradus',
                  element: <AppStudyStradus />
                },
              ]
            } ,
            {
              path: 'operations',
              children: [                 
                {
                  path: 'study',
                  element: <AppOperationStudies />
                },
              ]
            }  
          ]
        }, 
      ]
    },
    {
      path: '/maintenance',
      element: <CommonLayout />,
      children: [
        {
          path: '404',
          element: <MaintenanceError />
        },
        {
          path: '500',
          element: <MaintenanceError500 />
        },
        {
          path: 'under-construction',
          element: <MaintenanceUnderConstruction />
        }, 
      ]
    },
    // {
    //   path: '/auth',
    //   element: <CommonLayout />,
    //   children: [
    //     {
    //       path: 'login',
    //       element: <AuthLogin />
    //     }, 
    //     {
    //       path: 'forgot-password',
    //       element: <AuthForgotPassword />
    //     },
    //     {
    //       path: 'reset-password',
    //       element: <AuthResetPassword />
    //     },
    //     {
    //       path: 'check-mail',
    //       element: <AuthCheckMail />
    //     },
    //     {
    //       path: 'code-verification',
    //       element: <AuthCodeVerification />
    //     }
    //   ]
    // }
  ]
};

export default MainRoutes;

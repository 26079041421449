/* tslint:disable */
/* eslint-disable */
/**
 * eminence-pac-system
 * eminence-pac-system API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { HTTPValidationError } from '../models';
// @ts-ignore
import { InvoiceHistoryList } from '../models';
// @ts-ignore
import { InvoiceListCancelled } from '../models';
// @ts-ignore
import { InvoiceListGenerated } from '../models';
// @ts-ignore
import { InvoiceListNew } from '../models';
// @ts-ignore
import { InvoicePaymentList } from '../models';
// @ts-ignore
import { InvoiceStudyListCentre } from '../models';
/**
 * InvoiceApi - axios parameter creator
 * @export
 */
export const InvoiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add Invoice Payment
         * @param {number} invoiceId 
         * @param {number} amount 
         * @param {string} paymentReceivedAt 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addInvoicePayment: async (invoiceId: number, amount: number, paymentReceivedAt: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('addInvoicePayment', 'invoiceId', invoiceId)
            // verify required parameter 'amount' is not null or undefined
            assertParamExists('addInvoicePayment', 'amount', amount)
            // verify required parameter 'paymentReceivedAt' is not null or undefined
            assertParamExists('addInvoicePayment', 'paymentReceivedAt', paymentReceivedAt)
            const localVarPath = `/api/v1/invoice/centre/payment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (invoiceId !== undefined) {
                localVarQueryParameter['invoice_id'] = invoiceId;
            }

            if (amount !== undefined) {
                localVarQueryParameter['amount'] = amount;
            }

            if (paymentReceivedAt !== undefined) {
                localVarQueryParameter['paymentReceivedAt'] = paymentReceivedAt;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel Imaging Centre Invoices
         * @param {number} month 
         * @param {number} year 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelImagingCentreInvoices: async (month: number, year: number, requestBody: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'month' is not null or undefined
            assertParamExists('cancelImagingCentreInvoices', 'month', month)
            // verify required parameter 'year' is not null or undefined
            assertParamExists('cancelImagingCentreInvoices', 'year', year)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('cancelImagingCentreInvoices', 'requestBody', requestBody)
            const localVarPath = `/api/v1/invoicecentre/cancel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Invoice Payment
         * @param {number} invoiceId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInvoicePayment: async (invoiceId: number, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('deleteInvoicePayment', 'invoiceId', invoiceId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteInvoicePayment', 'id', id)
            const localVarPath = `/api/v1/invoice/{invoice_id}/payment/{id}`
                .replace(`{${"invoice_id"}}`, encodeURIComponent(String(invoiceId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate Imaging Centre Invoices
         * @param {number} month 
         * @param {number} year 
         * @param {boolean} sendEmails 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateImagingCentreInvoices: async (month: number, year: number, sendEmails: boolean, requestBody: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'month' is not null or undefined
            assertParamExists('generateImagingCentreInvoices', 'month', month)
            // verify required parameter 'year' is not null or undefined
            assertParamExists('generateImagingCentreInvoices', 'year', year)
            // verify required parameter 'sendEmails' is not null or undefined
            assertParamExists('generateImagingCentreInvoices', 'sendEmails', sendEmails)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('generateImagingCentreInvoices', 'requestBody', requestBody)
            const localVarPath = `/api/v1/invoicegenerate/centre`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (sendEmails !== undefined) {
                localVarQueryParameter['sendEmails'] = sendEmails;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Invoice History
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoiceHistory: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getInvoiceHistory', 'id', id)
            const localVarPath = `/api/v1/invoicecentre/history/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Invoice History Studies
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoiceHistoryStudies: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getInvoiceHistoryStudies', 'id', id)
            const localVarPath = `/api/v1/invoicecentre/history/studies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Invoice List Cancelled
         * @param {number} month 
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoiceListCancelled: async (month: number, year: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'month' is not null or undefined
            assertParamExists('getInvoiceListCancelled', 'month', month)
            // verify required parameter 'year' is not null or undefined
            assertParamExists('getInvoiceListCancelled', 'year', year)
            const localVarPath = `/api/v1/invoice/centre/{month}/{year}/cancelled`
                .replace(`{${"month"}}`, encodeURIComponent(String(month)))
                .replace(`{${"year"}}`, encodeURIComponent(String(year)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Invoice List Generated
         * @param {number} month 
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoiceListGenerated: async (month: number, year: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'month' is not null or undefined
            assertParamExists('getInvoiceListGenerated', 'month', month)
            // verify required parameter 'year' is not null or undefined
            assertParamExists('getInvoiceListGenerated', 'year', year)
            const localVarPath = `/api/v1/invoice/centre/{month}/{year}/generated`
                .replace(`{${"month"}}`, encodeURIComponent(String(month)))
                .replace(`{${"year"}}`, encodeURIComponent(String(year)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Invoice List New
         * @param {number} month 
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoiceListNew: async (month: number, year: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'month' is not null or undefined
            assertParamExists('getInvoiceListNew', 'month', month)
            // verify required parameter 'year' is not null or undefined
            assertParamExists('getInvoiceListNew', 'year', year)
            const localVarPath = `/api/v1/invoice/centre/{month}/{year}/new`
                .replace(`{${"month"}}`, encodeURIComponent(String(month)))
                .replace(`{${"year"}}`, encodeURIComponent(String(year)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Invoice Payments
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoicePayments: async (invoiceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('getInvoicePayments', 'invoiceId', invoiceId)
            const localVarPath = `/api/v1/invoice/centre/payment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (invoiceId !== undefined) {
                localVarQueryParameter['invoice_id'] = invoiceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Invoice Payment
         * @param {number} invoiceId 
         * @param {number} id 
         * @param {number} amount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInvoicePayment: async (invoiceId: number, id: number, amount: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('updateInvoicePayment', 'invoiceId', invoiceId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateInvoicePayment', 'id', id)
            // verify required parameter 'amount' is not null or undefined
            assertParamExists('updateInvoicePayment', 'amount', amount)
            const localVarPath = `/api/v1/invoice/{invoice_id}/payment/{id}`
                .replace(`{${"invoice_id"}}`, encodeURIComponent(String(invoiceId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (amount !== undefined) {
                localVarQueryParameter['amount'] = amount;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary View Excel
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viewExcel: async (invoiceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('viewExcel', 'invoiceId', invoiceId)
            const localVarPath = `/api/v1/invoice/view_excel/{invoice_id}`
                .replace(`{${"invoice_id"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary View Invoice
         * @param {number} invoiceId 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viewInvoice: async (invoiceId: number, type: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('viewInvoice', 'invoiceId', invoiceId)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('viewInvoice', 'type', type)
            const localVarPath = `/api/v1/invoice/view_pdf/{invoice_id}`
                .replace(`{${"invoice_id"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvoiceApi - functional programming interface
 * @export
 */
export const InvoiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvoiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add Invoice Payment
         * @param {number} invoiceId 
         * @param {number} amount 
         * @param {string} paymentReceivedAt 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addInvoicePayment(invoiceId: number, amount: number, paymentReceivedAt: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InvoicePaymentList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addInvoicePayment(invoiceId, amount, paymentReceivedAt, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancel Imaging Centre Invoices
         * @param {number} month 
         * @param {number} year 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelImagingCentreInvoices(month: number, year: number, requestBody: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelImagingCentreInvoices(month, year, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Invoice Payment
         * @param {number} invoiceId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteInvoicePayment(invoiceId: number, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InvoicePaymentList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteInvoicePayment(invoiceId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Generate Imaging Centre Invoices
         * @param {number} month 
         * @param {number} year 
         * @param {boolean} sendEmails 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateImagingCentreInvoices(month: number, year: number, sendEmails: boolean, requestBody: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateImagingCentreInvoices(month, year, sendEmails, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Invoice History
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvoiceHistory(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InvoiceHistoryList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvoiceHistory(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Invoice History Studies
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvoiceHistoryStudies(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InvoiceStudyListCentre>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvoiceHistoryStudies(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Invoice List Cancelled
         * @param {number} month 
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvoiceListCancelled(month: number, year: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InvoiceListCancelled>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvoiceListCancelled(month, year, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Invoice List Generated
         * @param {number} month 
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvoiceListGenerated(month: number, year: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InvoiceListGenerated>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvoiceListGenerated(month, year, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Invoice List New
         * @param {number} month 
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvoiceListNew(month: number, year: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InvoiceListNew>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvoiceListNew(month, year, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Invoice Payments
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvoicePayments(invoiceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InvoicePaymentList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvoicePayments(invoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Invoice Payment
         * @param {number} invoiceId 
         * @param {number} id 
         * @param {number} amount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInvoicePayment(invoiceId: number, id: number, amount: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InvoicePaymentList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInvoicePayment(invoiceId, id, amount, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary View Excel
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async viewExcel(invoiceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.viewExcel(invoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary View Invoice
         * @param {number} invoiceId 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async viewInvoice(invoiceId: number, type: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.viewInvoice(invoiceId, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InvoiceApi - factory interface
 * @export
 */
export const InvoiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvoiceApiFp(configuration)
    return {
        /**
         * 
         * @summary Add Invoice Payment
         * @param {number} invoiceId 
         * @param {number} amount 
         * @param {string} paymentReceivedAt 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addInvoicePayment(invoiceId: number, amount: number, paymentReceivedAt: string, options?: any): AxiosPromise<Array<InvoicePaymentList>> {
            return localVarFp.addInvoicePayment(invoiceId, amount, paymentReceivedAt, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancel Imaging Centre Invoices
         * @param {number} month 
         * @param {number} year 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelImagingCentreInvoices(month: number, year: number, requestBody: Array<number>, options?: any): AxiosPromise<any> {
            return localVarFp.cancelImagingCentreInvoices(month, year, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Invoice Payment
         * @param {number} invoiceId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInvoicePayment(invoiceId: number, id: number, options?: any): AxiosPromise<Array<InvoicePaymentList>> {
            return localVarFp.deleteInvoicePayment(invoiceId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generate Imaging Centre Invoices
         * @param {number} month 
         * @param {number} year 
         * @param {boolean} sendEmails 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateImagingCentreInvoices(month: number, year: number, sendEmails: boolean, requestBody: Array<number>, options?: any): AxiosPromise<any> {
            return localVarFp.generateImagingCentreInvoices(month, year, sendEmails, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Invoice History
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoiceHistory(id: number, options?: any): AxiosPromise<Array<InvoiceHistoryList>> {
            return localVarFp.getInvoiceHistory(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Invoice History Studies
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoiceHistoryStudies(id: number, options?: any): AxiosPromise<Array<InvoiceStudyListCentre>> {
            return localVarFp.getInvoiceHistoryStudies(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Invoice List Cancelled
         * @param {number} month 
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoiceListCancelled(month: number, year: number, options?: any): AxiosPromise<Array<InvoiceListCancelled>> {
            return localVarFp.getInvoiceListCancelled(month, year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Invoice List Generated
         * @param {number} month 
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoiceListGenerated(month: number, year: number, options?: any): AxiosPromise<Array<InvoiceListGenerated>> {
            return localVarFp.getInvoiceListGenerated(month, year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Invoice List New
         * @param {number} month 
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoiceListNew(month: number, year: number, options?: any): AxiosPromise<Array<InvoiceListNew>> {
            return localVarFp.getInvoiceListNew(month, year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Invoice Payments
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoicePayments(invoiceId: number, options?: any): AxiosPromise<Array<InvoicePaymentList>> {
            return localVarFp.getInvoicePayments(invoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Invoice Payment
         * @param {number} invoiceId 
         * @param {number} id 
         * @param {number} amount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInvoicePayment(invoiceId: number, id: number, amount: number, options?: any): AxiosPromise<Array<InvoicePaymentList>> {
            return localVarFp.updateInvoicePayment(invoiceId, id, amount, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary View Excel
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viewExcel(invoiceId: number, options?: any): AxiosPromise<any> {
            return localVarFp.viewExcel(invoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary View Invoice
         * @param {number} invoiceId 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viewInvoice(invoiceId: number, type: string, options?: any): AxiosPromise<any> {
            return localVarFp.viewInvoice(invoiceId, type, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for addInvoicePayment operation in InvoiceApi.
 * @export
 * @interface InvoiceApiAddInvoicePaymentRequest
 */
export interface InvoiceApiAddInvoicePaymentRequest {
    /**
     * 
     * @type {number}
     * @memberof InvoiceApiAddInvoicePayment
     */
    readonly invoiceId: number

    /**
     * 
     * @type {number}
     * @memberof InvoiceApiAddInvoicePayment
     */
    readonly amount: number

    /**
     * 
     * @type {string}
     * @memberof InvoiceApiAddInvoicePayment
     */
    readonly paymentReceivedAt: string
}

/**
 * Request parameters for cancelImagingCentreInvoices operation in InvoiceApi.
 * @export
 * @interface InvoiceApiCancelImagingCentreInvoicesRequest
 */
export interface InvoiceApiCancelImagingCentreInvoicesRequest {
    /**
     * 
     * @type {number}
     * @memberof InvoiceApiCancelImagingCentreInvoices
     */
    readonly month: number

    /**
     * 
     * @type {number}
     * @memberof InvoiceApiCancelImagingCentreInvoices
     */
    readonly year: number

    /**
     * 
     * @type {Array<number>}
     * @memberof InvoiceApiCancelImagingCentreInvoices
     */
    readonly requestBody: Array<number>
}

/**
 * Request parameters for deleteInvoicePayment operation in InvoiceApi.
 * @export
 * @interface InvoiceApiDeleteInvoicePaymentRequest
 */
export interface InvoiceApiDeleteInvoicePaymentRequest {
    /**
     * 
     * @type {number}
     * @memberof InvoiceApiDeleteInvoicePayment
     */
    readonly invoiceId: number

    /**
     * 
     * @type {number}
     * @memberof InvoiceApiDeleteInvoicePayment
     */
    readonly id: number
}

/**
 * Request parameters for generateImagingCentreInvoices operation in InvoiceApi.
 * @export
 * @interface InvoiceApiGenerateImagingCentreInvoicesRequest
 */
export interface InvoiceApiGenerateImagingCentreInvoicesRequest {
    /**
     * 
     * @type {number}
     * @memberof InvoiceApiGenerateImagingCentreInvoices
     */
    readonly month: number

    /**
     * 
     * @type {number}
     * @memberof InvoiceApiGenerateImagingCentreInvoices
     */
    readonly year: number

    /**
     * 
     * @type {boolean}
     * @memberof InvoiceApiGenerateImagingCentreInvoices
     */
    readonly sendEmails: boolean

    /**
     * 
     * @type {Array<number>}
     * @memberof InvoiceApiGenerateImagingCentreInvoices
     */
    readonly requestBody: Array<number>
}

/**
 * Request parameters for getInvoiceHistory operation in InvoiceApi.
 * @export
 * @interface InvoiceApiGetInvoiceHistoryRequest
 */
export interface InvoiceApiGetInvoiceHistoryRequest {
    /**
     * 
     * @type {number}
     * @memberof InvoiceApiGetInvoiceHistory
     */
    readonly id: number
}

/**
 * Request parameters for getInvoiceHistoryStudies operation in InvoiceApi.
 * @export
 * @interface InvoiceApiGetInvoiceHistoryStudiesRequest
 */
export interface InvoiceApiGetInvoiceHistoryStudiesRequest {
    /**
     * 
     * @type {number}
     * @memberof InvoiceApiGetInvoiceHistoryStudies
     */
    readonly id: number
}

/**
 * Request parameters for getInvoiceListCancelled operation in InvoiceApi.
 * @export
 * @interface InvoiceApiGetInvoiceListCancelledRequest
 */
export interface InvoiceApiGetInvoiceListCancelledRequest {
    /**
     * 
     * @type {number}
     * @memberof InvoiceApiGetInvoiceListCancelled
     */
    readonly month: number

    /**
     * 
     * @type {number}
     * @memberof InvoiceApiGetInvoiceListCancelled
     */
    readonly year: number
}

/**
 * Request parameters for getInvoiceListGenerated operation in InvoiceApi.
 * @export
 * @interface InvoiceApiGetInvoiceListGeneratedRequest
 */
export interface InvoiceApiGetInvoiceListGeneratedRequest {
    /**
     * 
     * @type {number}
     * @memberof InvoiceApiGetInvoiceListGenerated
     */
    readonly month: number

    /**
     * 
     * @type {number}
     * @memberof InvoiceApiGetInvoiceListGenerated
     */
    readonly year: number
}

/**
 * Request parameters for getInvoiceListNew operation in InvoiceApi.
 * @export
 * @interface InvoiceApiGetInvoiceListNewRequest
 */
export interface InvoiceApiGetInvoiceListNewRequest {
    /**
     * 
     * @type {number}
     * @memberof InvoiceApiGetInvoiceListNew
     */
    readonly month: number

    /**
     * 
     * @type {number}
     * @memberof InvoiceApiGetInvoiceListNew
     */
    readonly year: number
}

/**
 * Request parameters for getInvoicePayments operation in InvoiceApi.
 * @export
 * @interface InvoiceApiGetInvoicePaymentsRequest
 */
export interface InvoiceApiGetInvoicePaymentsRequest {
    /**
     * 
     * @type {number}
     * @memberof InvoiceApiGetInvoicePayments
     */
    readonly invoiceId: number
}

/**
 * Request parameters for updateInvoicePayment operation in InvoiceApi.
 * @export
 * @interface InvoiceApiUpdateInvoicePaymentRequest
 */
export interface InvoiceApiUpdateInvoicePaymentRequest {
    /**
     * 
     * @type {number}
     * @memberof InvoiceApiUpdateInvoicePayment
     */
    readonly invoiceId: number

    /**
     * 
     * @type {number}
     * @memberof InvoiceApiUpdateInvoicePayment
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof InvoiceApiUpdateInvoicePayment
     */
    readonly amount: number
}

/**
 * Request parameters for viewExcel operation in InvoiceApi.
 * @export
 * @interface InvoiceApiViewExcelRequest
 */
export interface InvoiceApiViewExcelRequest {
    /**
     * 
     * @type {number}
     * @memberof InvoiceApiViewExcel
     */
    readonly invoiceId: number
}

/**
 * Request parameters for viewInvoice operation in InvoiceApi.
 * @export
 * @interface InvoiceApiViewInvoiceRequest
 */
export interface InvoiceApiViewInvoiceRequest {
    /**
     * 
     * @type {number}
     * @memberof InvoiceApiViewInvoice
     */
    readonly invoiceId: number

    /**
     * 
     * @type {string}
     * @memberof InvoiceApiViewInvoice
     */
    readonly type: string
}

/**
 * InvoiceApi - object-oriented interface
 * @export
 * @class InvoiceApi
 * @extends {BaseAPI}
 */
export class InvoiceApi extends BaseAPI {
    /**
     * 
     * @summary Add Invoice Payment
     * @param {InvoiceApiAddInvoicePaymentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public addInvoicePayment(requestParameters: InvoiceApiAddInvoicePaymentRequest, options?: AxiosRequestConfig) {
        return InvoiceApiFp(this.configuration).addInvoicePayment(requestParameters.invoiceId, requestParameters.amount, requestParameters.paymentReceivedAt, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancel Imaging Centre Invoices
     * @param {InvoiceApiCancelImagingCentreInvoicesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public cancelImagingCentreInvoices(requestParameters: InvoiceApiCancelImagingCentreInvoicesRequest, options?: AxiosRequestConfig) {
        return InvoiceApiFp(this.configuration).cancelImagingCentreInvoices(requestParameters.month, requestParameters.year, requestParameters.requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Invoice Payment
     * @param {InvoiceApiDeleteInvoicePaymentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public deleteInvoicePayment(requestParameters: InvoiceApiDeleteInvoicePaymentRequest, options?: AxiosRequestConfig) {
        return InvoiceApiFp(this.configuration).deleteInvoicePayment(requestParameters.invoiceId, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generate Imaging Centre Invoices
     * @param {InvoiceApiGenerateImagingCentreInvoicesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public generateImagingCentreInvoices(requestParameters: InvoiceApiGenerateImagingCentreInvoicesRequest, options?: AxiosRequestConfig) {
        return InvoiceApiFp(this.configuration).generateImagingCentreInvoices(requestParameters.month, requestParameters.year, requestParameters.sendEmails, requestParameters.requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Invoice History
     * @param {InvoiceApiGetInvoiceHistoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public getInvoiceHistory(requestParameters: InvoiceApiGetInvoiceHistoryRequest, options?: AxiosRequestConfig) {
        return InvoiceApiFp(this.configuration).getInvoiceHistory(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Invoice History Studies
     * @param {InvoiceApiGetInvoiceHistoryStudiesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public getInvoiceHistoryStudies(requestParameters: InvoiceApiGetInvoiceHistoryStudiesRequest, options?: AxiosRequestConfig) {
        return InvoiceApiFp(this.configuration).getInvoiceHistoryStudies(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Invoice List Cancelled
     * @param {InvoiceApiGetInvoiceListCancelledRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public getInvoiceListCancelled(requestParameters: InvoiceApiGetInvoiceListCancelledRequest, options?: AxiosRequestConfig) {
        return InvoiceApiFp(this.configuration).getInvoiceListCancelled(requestParameters.month, requestParameters.year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Invoice List Generated
     * @param {InvoiceApiGetInvoiceListGeneratedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public getInvoiceListGenerated(requestParameters: InvoiceApiGetInvoiceListGeneratedRequest, options?: AxiosRequestConfig) {
        return InvoiceApiFp(this.configuration).getInvoiceListGenerated(requestParameters.month, requestParameters.year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Invoice List New
     * @param {InvoiceApiGetInvoiceListNewRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public getInvoiceListNew(requestParameters: InvoiceApiGetInvoiceListNewRequest, options?: AxiosRequestConfig) {
        return InvoiceApiFp(this.configuration).getInvoiceListNew(requestParameters.month, requestParameters.year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Invoice Payments
     * @param {InvoiceApiGetInvoicePaymentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public getInvoicePayments(requestParameters: InvoiceApiGetInvoicePaymentsRequest, options?: AxiosRequestConfig) {
        return InvoiceApiFp(this.configuration).getInvoicePayments(requestParameters.invoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Invoice Payment
     * @param {InvoiceApiUpdateInvoicePaymentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public updateInvoicePayment(requestParameters: InvoiceApiUpdateInvoicePaymentRequest, options?: AxiosRequestConfig) {
        return InvoiceApiFp(this.configuration).updateInvoicePayment(requestParameters.invoiceId, requestParameters.id, requestParameters.amount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary View Excel
     * @param {InvoiceApiViewExcelRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public viewExcel(requestParameters: InvoiceApiViewExcelRequest, options?: AxiosRequestConfig) {
        return InvoiceApiFp(this.configuration).viewExcel(requestParameters.invoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary View Invoice
     * @param {InvoiceApiViewInvoiceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public viewInvoice(requestParameters: InvoiceApiViewInvoiceRequest, options?: AxiosRequestConfig) {
        return InvoiceApiFp(this.configuration).viewInvoice(requestParameters.invoiceId, requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }
}

// third-party
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// project import
import menu from './menu';
import snackbar from './snackbar';
import productReducer from './product';
import invoice from './invoice_old';
import imagingCentreReducer from './imagingCentre';
import radiologistReducer from './radiologist';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  menu,
  snackbar, 
  // product: productReducer, 
  imagingCentre: imagingCentreReducer,
  radiologist: radiologistReducer,
  invoice
});

export default reducers;

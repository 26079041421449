/* tslint:disable */
/* eslint-disable */
/**
 * eminence-pac-system
 * eminence-pac-system API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { HTTPValidationError } from '../models';
// @ts-ignore
import { Radiologist } from '../models';
// @ts-ignore
import { RadiologistCreate } from '../models';
// @ts-ignore
import { RadiologistGet } from '../models';
// @ts-ignore
import { RadiologistUpdate } from '../models';
/**
 * RadiologistsApi - axios parameter creator
 * @export
 */
export const RadiologistsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Radiologist
         * @param {RadiologistCreate} radiologistCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRadiologist: async (radiologistCreate: RadiologistCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'radiologistCreate' is not null or undefined
            assertParamExists('createRadiologist', 'radiologistCreate', radiologistCreate)
            const localVarPath = `/api/v1/radiologists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(radiologistCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Radiologist
         * @param {number} radiologistId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRadiologist: async (radiologistId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'radiologistId' is not null or undefined
            assertParamExists('deleteRadiologist', 'radiologistId', radiologistId)
            const localVarPath = `/api/v1/radiologists/{radiologist_id}`
                .replace(`{${"radiologist_id"}}`, encodeURIComponent(String(radiologistId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Radiologist
         * @param {number} radiologistId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRadiologist: async (radiologistId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'radiologistId' is not null or undefined
            assertParamExists('getRadiologist', 'radiologistId', radiologistId)
            const localVarPath = `/api/v1/radiologists/{radiologist_id}`
                .replace(`{${"radiologist_id"}}`, encodeURIComponent(String(radiologistId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Radiologists
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRadiologists: async (sort?: string, range?: string, filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/radiologists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (range !== undefined) {
                localVarQueryParameter['range'] = range;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Radiologist
         * @param {number} radiologistId 
         * @param {RadiologistUpdate} radiologistUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRadiologist: async (radiologistId: number, radiologistUpdate: RadiologistUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'radiologistId' is not null or undefined
            assertParamExists('updateRadiologist', 'radiologistId', radiologistId)
            // verify required parameter 'radiologistUpdate' is not null or undefined
            assertParamExists('updateRadiologist', 'radiologistUpdate', radiologistUpdate)
            const localVarPath = `/api/v1/radiologists/{radiologist_id}`
                .replace(`{${"radiologist_id"}}`, encodeURIComponent(String(radiologistId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(radiologistUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RadiologistsApi - functional programming interface
 * @export
 */
export const RadiologistsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RadiologistsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Radiologist
         * @param {RadiologistCreate} radiologistCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRadiologist(radiologistCreate: RadiologistCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Radiologist>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRadiologist(radiologistCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Radiologist
         * @param {number} radiologistId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRadiologist(radiologistId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Radiologist>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRadiologist(radiologistId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Radiologist
         * @param {number} radiologistId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRadiologist(radiologistId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RadiologistGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRadiologist(radiologistId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Radiologists
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRadiologists(sort?: string, range?: string, filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Radiologist>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRadiologists(sort, range, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Radiologist
         * @param {number} radiologistId 
         * @param {RadiologistUpdate} radiologistUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRadiologist(radiologistId: number, radiologistUpdate: RadiologistUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Radiologist>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRadiologist(radiologistId, radiologistUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RadiologistsApi - factory interface
 * @export
 */
export const RadiologistsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RadiologistsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Radiologist
         * @param {RadiologistCreate} radiologistCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRadiologist(radiologistCreate: RadiologistCreate, options?: any): AxiosPromise<Radiologist> {
            return localVarFp.createRadiologist(radiologistCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Radiologist
         * @param {number} radiologistId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRadiologist(radiologistId: number, options?: any): AxiosPromise<Radiologist> {
            return localVarFp.deleteRadiologist(radiologistId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Radiologist
         * @param {number} radiologistId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRadiologist(radiologistId: number, options?: any): AxiosPromise<RadiologistGet> {
            return localVarFp.getRadiologist(radiologistId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Radiologists
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRadiologists(sort?: string, range?: string, filter?: string, options?: any): AxiosPromise<Array<Radiologist>> {
            return localVarFp.getRadiologists(sort, range, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Radiologist
         * @param {number} radiologistId 
         * @param {RadiologistUpdate} radiologistUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRadiologist(radiologistId: number, radiologistUpdate: RadiologistUpdate, options?: any): AxiosPromise<Radiologist> {
            return localVarFp.updateRadiologist(radiologistId, radiologistUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createRadiologist operation in RadiologistsApi.
 * @export
 * @interface RadiologistsApiCreateRadiologistRequest
 */
export interface RadiologistsApiCreateRadiologistRequest {
    /**
     * 
     * @type {RadiologistCreate}
     * @memberof RadiologistsApiCreateRadiologist
     */
    readonly radiologistCreate: RadiologistCreate
}

/**
 * Request parameters for deleteRadiologist operation in RadiologistsApi.
 * @export
 * @interface RadiologistsApiDeleteRadiologistRequest
 */
export interface RadiologistsApiDeleteRadiologistRequest {
    /**
     * 
     * @type {number}
     * @memberof RadiologistsApiDeleteRadiologist
     */
    readonly radiologistId: number
}

/**
 * Request parameters for getRadiologist operation in RadiologistsApi.
 * @export
 * @interface RadiologistsApiGetRadiologistRequest
 */
export interface RadiologistsApiGetRadiologistRequest {
    /**
     * 
     * @type {number}
     * @memberof RadiologistsApiGetRadiologist
     */
    readonly radiologistId: number
}

/**
 * Request parameters for getRadiologists operation in RadiologistsApi.
 * @export
 * @interface RadiologistsApiGetRadiologistsRequest
 */
export interface RadiologistsApiGetRadiologistsRequest {
    /**
     * Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
     * @type {string}
     * @memberof RadiologistsApiGetRadiologists
     */
    readonly sort?: string

    /**
     * Format: &#x60;[start, end]&#x60;
     * @type {string}
     * @memberof RadiologistsApiGetRadiologists
     */
    readonly range?: string

    /**
     * Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
     * @type {string}
     * @memberof RadiologistsApiGetRadiologists
     */
    readonly filter?: string
}

/**
 * Request parameters for updateRadiologist operation in RadiologistsApi.
 * @export
 * @interface RadiologistsApiUpdateRadiologistRequest
 */
export interface RadiologistsApiUpdateRadiologistRequest {
    /**
     * 
     * @type {number}
     * @memberof RadiologistsApiUpdateRadiologist
     */
    readonly radiologistId: number

    /**
     * 
     * @type {RadiologistUpdate}
     * @memberof RadiologistsApiUpdateRadiologist
     */
    readonly radiologistUpdate: RadiologistUpdate
}

/**
 * RadiologistsApi - object-oriented interface
 * @export
 * @class RadiologistsApi
 * @extends {BaseAPI}
 */
export class RadiologistsApi extends BaseAPI {
    /**
     * 
     * @summary Create Radiologist
     * @param {RadiologistsApiCreateRadiologistRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RadiologistsApi
     */
    public createRadiologist(requestParameters: RadiologistsApiCreateRadiologistRequest, options?: AxiosRequestConfig) {
        return RadiologistsApiFp(this.configuration).createRadiologist(requestParameters.radiologistCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Radiologist
     * @param {RadiologistsApiDeleteRadiologistRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RadiologistsApi
     */
    public deleteRadiologist(requestParameters: RadiologistsApiDeleteRadiologistRequest, options?: AxiosRequestConfig) {
        return RadiologistsApiFp(this.configuration).deleteRadiologist(requestParameters.radiologistId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Radiologist
     * @param {RadiologistsApiGetRadiologistRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RadiologistsApi
     */
    public getRadiologist(requestParameters: RadiologistsApiGetRadiologistRequest, options?: AxiosRequestConfig) {
        return RadiologistsApiFp(this.configuration).getRadiologist(requestParameters.radiologistId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Radiologists
     * @param {RadiologistsApiGetRadiologistsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RadiologistsApi
     */
    public getRadiologists(requestParameters: RadiologistsApiGetRadiologistsRequest = {}, options?: AxiosRequestConfig) {
        return RadiologistsApiFp(this.configuration).getRadiologists(requestParameters.sort, requestParameters.range, requestParameters.filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Radiologist
     * @param {RadiologistsApiUpdateRadiologistRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RadiologistsApi
     */
    public updateRadiologist(requestParameters: RadiologistsApiUpdateRadiologistRequest, options?: AxiosRequestConfig) {
        return RadiologistsApiFp(this.configuration).updateRadiologist(requestParameters.radiologistId, requestParameters.radiologistUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}

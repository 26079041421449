// third-party --------------------------------------
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// project imports ----------------------------------
// import axios from 'utils/axios';
// import { dispatch } from '../index';
import { addAsyncThunk } from '../helper';

// Auto Generated openAPI --------------------------------
import { imagingCentresApi } from 'providers/env'
import { ImagingCentreGet as ImagingCentre, ImagingCentre as ImagingCentreList } from 'generated/models'
import { ImagingCentresApiGetImagingCentresRequest, ImagingCentresApiCreateImagingCentreRequest, ImagingCentresApiUpdateImagingCentreRequest } from '../../generated/api/imaging-centres-api';
// --------------------------------------------------

type ImagingCentreState = {
  imagingCentre: ImagingCentre | null;
  imagingCentres: { "list": ImagingCentreList[], totalRecords: number };
  status: 'idle' | 'loading' | 'failed';
  error: string | null;
}

const initialState: ImagingCentreState = {
  imagingCentre: null,
  imagingCentres: { "list": [], totalRecords: 0 },
  status: 'idle',
  error: null
};


// code for async thunks and the slice... 
// Async thunks --------------------------------------
export const getImagingCentres = createAsyncThunk('imagingCentres/getStatus',
  async (requestParameters: ImagingCentresApiGetImagingCentresRequest = {}) => {
    const response = await imagingCentresApi.getImagingCentres(requestParameters);
    return { "list": response.data, totalRecords: response.headers["content-range"].split('/')[1] };
  });

export const createImagingCentre = createAsyncThunk('imagingCentres/createStatus',
  async (itemData: ImagingCentresApiCreateImagingCentreRequest) => {
    const response = await imagingCentresApi.createImagingCentre(itemData)
    return response.data
  }
);

export const deleteImagingCentre = createAsyncThunk('imagingCentres/deleteStatus',
  async (imagingCentreId: number) => {
    const response = await imagingCentresApi.deleteImagingCentre({ imagingCentreId });
    return response.data;
  }
);

export const getImagingCentre = createAsyncThunk('imagingCentres/getSingleStatus',
  async (imagingCentreId: number) => {
    const response = await imagingCentresApi.getImagingCentre({ imagingCentreId });
    return response.data;
  }
);

export const updateImagingCentre = createAsyncThunk('imagingCentres/updateStatus',
  async (updateData: ImagingCentresApiUpdateImagingCentreRequest) => {
    const response = await imagingCentresApi.updateImagingCentre(updateData);
    return response.data;
  }
);

const imagingCentreSlice = createSlice({
  name: 'imagingCentre',
  initialState,
  reducers: {
    // Any synchronous actions would go here
  },
  extraReducers: (builder) => {
    // the helper function to add the cases for each async thunk
    addAsyncThunk(builder, getImagingCentres, 'imagingCentres');
    addAsyncThunk(builder, createImagingCentre, 'imagingCentre');
    addAsyncThunk(builder, deleteImagingCentre, 'deleteStatus');
    addAsyncThunk(builder, getImagingCentre, 'getSingleStatus');
    addAsyncThunk(builder, updateImagingCentre, 'updateStatus');
  },
});

export default imagingCentreSlice.reducer;

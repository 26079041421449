/* tslint:disable */
/* eslint-disable */
/**
 * eminence-pac-system
 * eminence-pac-system API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { HTTPValidationError } from '../models';
// @ts-ignore
import { RadiologistExtraCharges } from '../models';
// @ts-ignore
import { RadiologistExtraChargesCreate } from '../models';
// @ts-ignore
import { RadiologistExtraChargesUpdate } from '../models';
/**
 * RadiologistExtraChargesApi - axios parameter creator
 * @export
 */
export const RadiologistExtraChargesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Radiologist Extra Charge
         * @param {RadiologistExtraChargesCreate} radiologistExtraChargesCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRadiologistExtraCharge: async (radiologistExtraChargesCreate: RadiologistExtraChargesCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'radiologistExtraChargesCreate' is not null or undefined
            assertParamExists('createRadiologistExtraCharge', 'radiologistExtraChargesCreate', radiologistExtraChargesCreate)
            const localVarPath = `/api/v1/radiologist/extra-charges`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(radiologistExtraChargesCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Radiologist Extra Charge
         * @param {number} extraChargeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRadiologistExtraCharge: async (extraChargeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'extraChargeId' is not null or undefined
            assertParamExists('deleteRadiologistExtraCharge', 'extraChargeId', extraChargeId)
            const localVarPath = `/api/v1/radiologist/extra-charges/{extra_charge_id}`
                .replace(`{${"extra_charge_id"}}`, encodeURIComponent(String(extraChargeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Radiologist Extra Charges
         * @param {number} radiologistId 
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRadiologistExtraCharges: async (radiologistId: number, sort?: string, range?: string, filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'radiologistId' is not null or undefined
            assertParamExists('getRadiologistExtraCharges', 'radiologistId', radiologistId)
            const localVarPath = `/api/v1/radiologist/{radiologist_id}/extra-charges`
                .replace(`{${"radiologist_id"}}`, encodeURIComponent(String(radiologistId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (range !== undefined) {
                localVarQueryParameter['range'] = range;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Radiologist Extra Charge
         * @param {number} extraChargeId 
         * @param {RadiologistExtraChargesUpdate} radiologistExtraChargesUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRadiologistExtraCharge: async (extraChargeId: number, radiologistExtraChargesUpdate: RadiologistExtraChargesUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'extraChargeId' is not null or undefined
            assertParamExists('updateRadiologistExtraCharge', 'extraChargeId', extraChargeId)
            // verify required parameter 'radiologistExtraChargesUpdate' is not null or undefined
            assertParamExists('updateRadiologistExtraCharge', 'radiologistExtraChargesUpdate', radiologistExtraChargesUpdate)
            const localVarPath = `/api/v1/radiologist/extra-charges/{extra_charge_id}`
                .replace(`{${"extra_charge_id"}}`, encodeURIComponent(String(extraChargeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(radiologistExtraChargesUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RadiologistExtraChargesApi - functional programming interface
 * @export
 */
export const RadiologistExtraChargesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RadiologistExtraChargesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Radiologist Extra Charge
         * @param {RadiologistExtraChargesCreate} radiologistExtraChargesCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRadiologistExtraCharge(radiologistExtraChargesCreate: RadiologistExtraChargesCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RadiologistExtraCharges>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRadiologistExtraCharge(radiologistExtraChargesCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Radiologist Extra Charge
         * @param {number} extraChargeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRadiologistExtraCharge(extraChargeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRadiologistExtraCharge(extraChargeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Radiologist Extra Charges
         * @param {number} radiologistId 
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRadiologistExtraCharges(radiologistId: number, sort?: string, range?: string, filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RadiologistExtraCharges>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRadiologistExtraCharges(radiologistId, sort, range, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Radiologist Extra Charge
         * @param {number} extraChargeId 
         * @param {RadiologistExtraChargesUpdate} radiologistExtraChargesUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRadiologistExtraCharge(extraChargeId: number, radiologistExtraChargesUpdate: RadiologistExtraChargesUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RadiologistExtraCharges>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRadiologistExtraCharge(extraChargeId, radiologistExtraChargesUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RadiologistExtraChargesApi - factory interface
 * @export
 */
export const RadiologistExtraChargesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RadiologistExtraChargesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Radiologist Extra Charge
         * @param {RadiologistExtraChargesCreate} radiologistExtraChargesCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRadiologistExtraCharge(radiologistExtraChargesCreate: RadiologistExtraChargesCreate, options?: any): AxiosPromise<RadiologistExtraCharges> {
            return localVarFp.createRadiologistExtraCharge(radiologistExtraChargesCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Radiologist Extra Charge
         * @param {number} extraChargeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRadiologistExtraCharge(extraChargeId: number, options?: any): AxiosPromise<any> {
            return localVarFp.deleteRadiologistExtraCharge(extraChargeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Radiologist Extra Charges
         * @param {number} radiologistId 
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRadiologistExtraCharges(radiologistId: number, sort?: string, range?: string, filter?: string, options?: any): AxiosPromise<Array<RadiologistExtraCharges>> {
            return localVarFp.getRadiologistExtraCharges(radiologistId, sort, range, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Radiologist Extra Charge
         * @param {number} extraChargeId 
         * @param {RadiologistExtraChargesUpdate} radiologistExtraChargesUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRadiologistExtraCharge(extraChargeId: number, radiologistExtraChargesUpdate: RadiologistExtraChargesUpdate, options?: any): AxiosPromise<RadiologistExtraCharges> {
            return localVarFp.updateRadiologistExtraCharge(extraChargeId, radiologistExtraChargesUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createRadiologistExtraCharge operation in RadiologistExtraChargesApi.
 * @export
 * @interface RadiologistExtraChargesApiCreateRadiologistExtraChargeRequest
 */
export interface RadiologistExtraChargesApiCreateRadiologistExtraChargeRequest {
    /**
     * 
     * @type {RadiologistExtraChargesCreate}
     * @memberof RadiologistExtraChargesApiCreateRadiologistExtraCharge
     */
    readonly radiologistExtraChargesCreate: RadiologistExtraChargesCreate
}

/**
 * Request parameters for deleteRadiologistExtraCharge operation in RadiologistExtraChargesApi.
 * @export
 * @interface RadiologistExtraChargesApiDeleteRadiologistExtraChargeRequest
 */
export interface RadiologistExtraChargesApiDeleteRadiologistExtraChargeRequest {
    /**
     * 
     * @type {number}
     * @memberof RadiologistExtraChargesApiDeleteRadiologistExtraCharge
     */
    readonly extraChargeId: number
}

/**
 * Request parameters for getRadiologistExtraCharges operation in RadiologistExtraChargesApi.
 * @export
 * @interface RadiologistExtraChargesApiGetRadiologistExtraChargesRequest
 */
export interface RadiologistExtraChargesApiGetRadiologistExtraChargesRequest {
    /**
     * 
     * @type {number}
     * @memberof RadiologistExtraChargesApiGetRadiologistExtraCharges
     */
    readonly radiologistId: number

    /**
     * Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
     * @type {string}
     * @memberof RadiologistExtraChargesApiGetRadiologistExtraCharges
     */
    readonly sort?: string

    /**
     * Format: &#x60;[start, end]&#x60;
     * @type {string}
     * @memberof RadiologistExtraChargesApiGetRadiologistExtraCharges
     */
    readonly range?: string

    /**
     * Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
     * @type {string}
     * @memberof RadiologistExtraChargesApiGetRadiologistExtraCharges
     */
    readonly filter?: string
}

/**
 * Request parameters for updateRadiologistExtraCharge operation in RadiologistExtraChargesApi.
 * @export
 * @interface RadiologistExtraChargesApiUpdateRadiologistExtraChargeRequest
 */
export interface RadiologistExtraChargesApiUpdateRadiologistExtraChargeRequest {
    /**
     * 
     * @type {number}
     * @memberof RadiologistExtraChargesApiUpdateRadiologistExtraCharge
     */
    readonly extraChargeId: number

    /**
     * 
     * @type {RadiologistExtraChargesUpdate}
     * @memberof RadiologistExtraChargesApiUpdateRadiologistExtraCharge
     */
    readonly radiologistExtraChargesUpdate: RadiologistExtraChargesUpdate
}

/**
 * RadiologistExtraChargesApi - object-oriented interface
 * @export
 * @class RadiologistExtraChargesApi
 * @extends {BaseAPI}
 */
export class RadiologistExtraChargesApi extends BaseAPI {
    /**
     * 
     * @summary Create Radiologist Extra Charge
     * @param {RadiologistExtraChargesApiCreateRadiologistExtraChargeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RadiologistExtraChargesApi
     */
    public createRadiologistExtraCharge(requestParameters: RadiologistExtraChargesApiCreateRadiologistExtraChargeRequest, options?: AxiosRequestConfig) {
        return RadiologistExtraChargesApiFp(this.configuration).createRadiologistExtraCharge(requestParameters.radiologistExtraChargesCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Radiologist Extra Charge
     * @param {RadiologistExtraChargesApiDeleteRadiologistExtraChargeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RadiologistExtraChargesApi
     */
    public deleteRadiologistExtraCharge(requestParameters: RadiologistExtraChargesApiDeleteRadiologistExtraChargeRequest, options?: AxiosRequestConfig) {
        return RadiologistExtraChargesApiFp(this.configuration).deleteRadiologistExtraCharge(requestParameters.extraChargeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Radiologist Extra Charges
     * @param {RadiologistExtraChargesApiGetRadiologistExtraChargesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RadiologistExtraChargesApi
     */
    public getRadiologistExtraCharges(requestParameters: RadiologistExtraChargesApiGetRadiologistExtraChargesRequest, options?: AxiosRequestConfig) {
        return RadiologistExtraChargesApiFp(this.configuration).getRadiologistExtraCharges(requestParameters.radiologistId, requestParameters.sort, requestParameters.range, requestParameters.filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Radiologist Extra Charge
     * @param {RadiologistExtraChargesApiUpdateRadiologistExtraChargeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RadiologistExtraChargesApi
     */
    public updateRadiologistExtraCharge(requestParameters: RadiologistExtraChargesApiUpdateRadiologistExtraChargeRequest, options?: AxiosRequestConfig) {
        return RadiologistExtraChargesApiFp(this.configuration).updateRadiologistExtraCharge(requestParameters.extraChargeId, requestParameters.radiologistExtraChargesUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}

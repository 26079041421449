/* tslint:disable */
/* eslint-disable */
/**
 * eminence-pac-system
 * eminence-pac-system API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { HTTPValidationError } from '../models';
// @ts-ignore
import { ImagingCentre } from '../models';
// @ts-ignore
import { ImagingCentreCreate } from '../models';
// @ts-ignore
import { ImagingCentreGet } from '../models';
// @ts-ignore
import { ImagingCentreUpdate } from '../models';
/**
 * ImagingCentresApi - axios parameter creator
 * @export
 */
export const ImagingCentresApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Imaging Centre
         * @param {ImagingCentreCreate} imagingCentreCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createImagingCentre: async (imagingCentreCreate: ImagingCentreCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'imagingCentreCreate' is not null or undefined
            assertParamExists('createImagingCentre', 'imagingCentreCreate', imagingCentreCreate)
            const localVarPath = `/api/v1/imaging-centres`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(imagingCentreCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Imaging Centre
         * @param {number} imagingCentreId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImagingCentre: async (imagingCentreId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'imagingCentreId' is not null or undefined
            assertParamExists('deleteImagingCentre', 'imagingCentreId', imagingCentreId)
            const localVarPath = `/api/v1/imaging-centres/{imaging_centre_id}`
                .replace(`{${"imaging_centre_id"}}`, encodeURIComponent(String(imagingCentreId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Imaging Centre
         * @param {number} imagingCentreId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImagingCentre: async (imagingCentreId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'imagingCentreId' is not null or undefined
            assertParamExists('getImagingCentre', 'imagingCentreId', imagingCentreId)
            const localVarPath = `/api/v1/imaging-centres/{imaging_centre_id}`
                .replace(`{${"imaging_centre_id"}}`, encodeURIComponent(String(imagingCentreId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Imaging Centres
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImagingCentres: async (sort?: string, range?: string, filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/imaging-centres`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (range !== undefined) {
                localVarQueryParameter['range'] = range;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Imaging Centre
         * @param {number} imagingCentreId 
         * @param {ImagingCentreUpdate} imagingCentreUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateImagingCentre: async (imagingCentreId: number, imagingCentreUpdate: ImagingCentreUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'imagingCentreId' is not null or undefined
            assertParamExists('updateImagingCentre', 'imagingCentreId', imagingCentreId)
            // verify required parameter 'imagingCentreUpdate' is not null or undefined
            assertParamExists('updateImagingCentre', 'imagingCentreUpdate', imagingCentreUpdate)
            const localVarPath = `/api/v1/imaging-centres/{imaging_centre_id}`
                .replace(`{${"imaging_centre_id"}}`, encodeURIComponent(String(imagingCentreId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(imagingCentreUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ImagingCentresApi - functional programming interface
 * @export
 */
export const ImagingCentresApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ImagingCentresApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Imaging Centre
         * @param {ImagingCentreCreate} imagingCentreCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createImagingCentre(imagingCentreCreate: ImagingCentreCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImagingCentre>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createImagingCentre(imagingCentreCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Imaging Centre
         * @param {number} imagingCentreId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteImagingCentre(imagingCentreId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImagingCentre>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteImagingCentre(imagingCentreId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Imaging Centre
         * @param {number} imagingCentreId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getImagingCentre(imagingCentreId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImagingCentreGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getImagingCentre(imagingCentreId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Imaging Centres
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getImagingCentres(sort?: string, range?: string, filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ImagingCentre>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getImagingCentres(sort, range, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Imaging Centre
         * @param {number} imagingCentreId 
         * @param {ImagingCentreUpdate} imagingCentreUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateImagingCentre(imagingCentreId: number, imagingCentreUpdate: ImagingCentreUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImagingCentre>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateImagingCentre(imagingCentreId, imagingCentreUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ImagingCentresApi - factory interface
 * @export
 */
export const ImagingCentresApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ImagingCentresApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Imaging Centre
         * @param {ImagingCentreCreate} imagingCentreCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createImagingCentre(imagingCentreCreate: ImagingCentreCreate, options?: any): AxiosPromise<ImagingCentre> {
            return localVarFp.createImagingCentre(imagingCentreCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Imaging Centre
         * @param {number} imagingCentreId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImagingCentre(imagingCentreId: number, options?: any): AxiosPromise<ImagingCentre> {
            return localVarFp.deleteImagingCentre(imagingCentreId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Imaging Centre
         * @param {number} imagingCentreId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImagingCentre(imagingCentreId: number, options?: any): AxiosPromise<ImagingCentreGet> {
            return localVarFp.getImagingCentre(imagingCentreId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Imaging Centres
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImagingCentres(sort?: string, range?: string, filter?: string, options?: any): AxiosPromise<Array<ImagingCentre>> {
            return localVarFp.getImagingCentres(sort, range, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Imaging Centre
         * @param {number} imagingCentreId 
         * @param {ImagingCentreUpdate} imagingCentreUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateImagingCentre(imagingCentreId: number, imagingCentreUpdate: ImagingCentreUpdate, options?: any): AxiosPromise<ImagingCentre> {
            return localVarFp.updateImagingCentre(imagingCentreId, imagingCentreUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createImagingCentre operation in ImagingCentresApi.
 * @export
 * @interface ImagingCentresApiCreateImagingCentreRequest
 */
export interface ImagingCentresApiCreateImagingCentreRequest {
    /**
     * 
     * @type {ImagingCentreCreate}
     * @memberof ImagingCentresApiCreateImagingCentre
     */
    readonly imagingCentreCreate: ImagingCentreCreate
}

/**
 * Request parameters for deleteImagingCentre operation in ImagingCentresApi.
 * @export
 * @interface ImagingCentresApiDeleteImagingCentreRequest
 */
export interface ImagingCentresApiDeleteImagingCentreRequest {
    /**
     * 
     * @type {number}
     * @memberof ImagingCentresApiDeleteImagingCentre
     */
    readonly imagingCentreId: number
}

/**
 * Request parameters for getImagingCentre operation in ImagingCentresApi.
 * @export
 * @interface ImagingCentresApiGetImagingCentreRequest
 */
export interface ImagingCentresApiGetImagingCentreRequest {
    /**
     * 
     * @type {number}
     * @memberof ImagingCentresApiGetImagingCentre
     */
    readonly imagingCentreId: number
}

/**
 * Request parameters for getImagingCentres operation in ImagingCentresApi.
 * @export
 * @interface ImagingCentresApiGetImagingCentresRequest
 */
export interface ImagingCentresApiGetImagingCentresRequest {
    /**
     * Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
     * @type {string}
     * @memberof ImagingCentresApiGetImagingCentres
     */
    readonly sort?: string

    /**
     * Format: &#x60;[start, end]&#x60;
     * @type {string}
     * @memberof ImagingCentresApiGetImagingCentres
     */
    readonly range?: string

    /**
     * Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
     * @type {string}
     * @memberof ImagingCentresApiGetImagingCentres
     */
    readonly filter?: string
}

/**
 * Request parameters for updateImagingCentre operation in ImagingCentresApi.
 * @export
 * @interface ImagingCentresApiUpdateImagingCentreRequest
 */
export interface ImagingCentresApiUpdateImagingCentreRequest {
    /**
     * 
     * @type {number}
     * @memberof ImagingCentresApiUpdateImagingCentre
     */
    readonly imagingCentreId: number

    /**
     * 
     * @type {ImagingCentreUpdate}
     * @memberof ImagingCentresApiUpdateImagingCentre
     */
    readonly imagingCentreUpdate: ImagingCentreUpdate
}

/**
 * ImagingCentresApi - object-oriented interface
 * @export
 * @class ImagingCentresApi
 * @extends {BaseAPI}
 */
export class ImagingCentresApi extends BaseAPI {
    /**
     * 
     * @summary Create Imaging Centre
     * @param {ImagingCentresApiCreateImagingCentreRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagingCentresApi
     */
    public createImagingCentre(requestParameters: ImagingCentresApiCreateImagingCentreRequest, options?: AxiosRequestConfig) {
        return ImagingCentresApiFp(this.configuration).createImagingCentre(requestParameters.imagingCentreCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Imaging Centre
     * @param {ImagingCentresApiDeleteImagingCentreRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagingCentresApi
     */
    public deleteImagingCentre(requestParameters: ImagingCentresApiDeleteImagingCentreRequest, options?: AxiosRequestConfig) {
        return ImagingCentresApiFp(this.configuration).deleteImagingCentre(requestParameters.imagingCentreId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Imaging Centre
     * @param {ImagingCentresApiGetImagingCentreRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagingCentresApi
     */
    public getImagingCentre(requestParameters: ImagingCentresApiGetImagingCentreRequest, options?: AxiosRequestConfig) {
        return ImagingCentresApiFp(this.configuration).getImagingCentre(requestParameters.imagingCentreId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Imaging Centres
     * @param {ImagingCentresApiGetImagingCentresRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagingCentresApi
     */
    public getImagingCentres(requestParameters: ImagingCentresApiGetImagingCentresRequest = {}, options?: AxiosRequestConfig) {
        return ImagingCentresApiFp(this.configuration).getImagingCentres(requestParameters.sort, requestParameters.range, requestParameters.filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Imaging Centre
     * @param {ImagingCentresApiUpdateImagingCentreRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagingCentresApi
     */
    public updateImagingCentre(requestParameters: ImagingCentresApiUpdateImagingCentreRequest, options?: AxiosRequestConfig) {
        return ImagingCentresApiFp(this.configuration).updateImagingCentre(requestParameters.imagingCentreId, requestParameters.imagingCentreUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}

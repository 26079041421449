/* tslint:disable */
/* eslint-disable */
/**
 * eminence-pac-system
 * eminence-pac-system API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ExternalStudyListSchema } from '../models';
// @ts-ignore
import { HTTPValidationError } from '../models';
// @ts-ignore
import { StudyCreateSchema } from '../models';
// @ts-ignore
import { StudyListSchema } from '../models';
// @ts-ignore
import { StudySchema } from '../models';
// @ts-ignore
import { StudyUpdateSchema } from '../models';
// @ts-ignore
import { ZeroChargesSchemaImagingCentre } from '../models';
// @ts-ignore
import { ZeroChargesSchemaRadiologist } from '../models';
/**
 * StudyApi - axios parameter creator
 * @export
 */
export const StudyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Study
         * @param {StudyCreateSchema} studyCreateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStudy: async (studyCreateSchema: StudyCreateSchema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'studyCreateSchema' is not null or undefined
            assertParamExists('createStudy', 'studyCreateSchema', studyCreateSchema)
            const localVarPath = `/api/v1/studies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(studyCreateSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get External Studies
         * @param {string} fromDate 
         * @param {string} toDate 
         * @param {string} status 
         * @param {string} modality 
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExternalStudies: async (fromDate: string, toDate: string, status: string, modality: string, sort?: string, range?: string, filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fromDate' is not null or undefined
            assertParamExists('getExternalStudies', 'fromDate', fromDate)
            // verify required parameter 'toDate' is not null or undefined
            assertParamExists('getExternalStudies', 'toDate', toDate)
            // verify required parameter 'status' is not null or undefined
            assertParamExists('getExternalStudies', 'status', status)
            // verify required parameter 'modality' is not null or undefined
            assertParamExists('getExternalStudies', 'modality', modality)
            const localVarPath = `/api/v1/studies/nandico`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (modality !== undefined) {
                localVarQueryParameter['modality'] = modality;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (range !== undefined) {
                localVarQueryParameter['range'] = range;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get External Studies Stradus
         * @param {string} fromDate 
         * @param {string} toDate 
         * @param {string} status 
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExternalStudiesStradus: async (fromDate: string, toDate: string, status: string, sort?: string, range?: string, filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fromDate' is not null or undefined
            assertParamExists('getExternalStudiesStradus', 'fromDate', fromDate)
            // verify required parameter 'toDate' is not null or undefined
            assertParamExists('getExternalStudiesStradus', 'toDate', toDate)
            // verify required parameter 'status' is not null or undefined
            assertParamExists('getExternalStudiesStradus', 'status', status)
            const localVarPath = `/api/v1/studies/stradus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (range !== undefined) {
                localVarQueryParameter['range'] = range;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Imaging Centre Zero Charges
         * @param {number} month 
         * @param {number} year 
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImagingCentreZeroCharges: async (month: number, year: number, sort?: string, range?: string, filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'month' is not null or undefined
            assertParamExists('getImagingCentreZeroCharges', 'month', month)
            // verify required parameter 'year' is not null or undefined
            assertParamExists('getImagingCentreZeroCharges', 'year', year)
            const localVarPath = `/api/v1/studies/imaging-centre/zero-charges/{month}/{year}`
                .replace(`{${"month"}}`, encodeURIComponent(String(month)))
                .replace(`{${"year"}}`, encodeURIComponent(String(year)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (range !== undefined) {
                localVarQueryParameter['range'] = range;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Nandico Study Title
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNandicoStudyTitle: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getNandicoStudyTitle', 'id', id)
            const localVarPath = `/api/v1/studies/nandico/study/title/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Radiologist Zero Charges
         * @param {number} month 
         * @param {number} year 
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRadiologistZeroCharges: async (month: number, year: number, sort?: string, range?: string, filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'month' is not null or undefined
            assertParamExists('getRadiologistZeroCharges', 'month', month)
            // verify required parameter 'year' is not null or undefined
            assertParamExists('getRadiologistZeroCharges', 'year', year)
            const localVarPath = `/api/v1/studies/radiologist/zero-charges/{month}/{year}`
                .replace(`{${"month"}}`, encodeURIComponent(String(month)))
                .replace(`{${"year"}}`, encodeURIComponent(String(year)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (range !== undefined) {
                localVarQueryParameter['range'] = range;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Stradus Reports
         * @param {string} customerId 
         * @param {string} patientId 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStradusReports: async (customerId: string, patientId: string, orderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getStradusReports', 'customerId', customerId)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getStradusReports', 'patientId', patientId)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('getStradusReports', 'orderId', orderId)
            const localVarPath = `/api/v1/studies/stradus/reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (customerId !== undefined) {
                localVarQueryParameter['customer_id'] = customerId;
            }

            if (patientId !== undefined) {
                localVarQueryParameter['patient_id'] = patientId;
            }

            if (orderId !== undefined) {
                localVarQueryParameter['order_id'] = orderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Studies
         * @param {string} fromDate 
         * @param {string} toDate 
         * @param {number} centreId 
         * @param {number} radiologistId 
         * @param {string} patientName 
         * @param {string} uid 
         * @param {string} modality 
         * @param {string} category 
         * @param {string} bodyparts 
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudies: async (fromDate: string, toDate: string, centreId: number, radiologistId: number, patientName: string, uid: string, modality: string, category: string, bodyparts: string, sort?: string, range?: string, filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fromDate' is not null or undefined
            assertParamExists('getStudies', 'fromDate', fromDate)
            // verify required parameter 'toDate' is not null or undefined
            assertParamExists('getStudies', 'toDate', toDate)
            // verify required parameter 'centreId' is not null or undefined
            assertParamExists('getStudies', 'centreId', centreId)
            // verify required parameter 'radiologistId' is not null or undefined
            assertParamExists('getStudies', 'radiologistId', radiologistId)
            // verify required parameter 'patientName' is not null or undefined
            assertParamExists('getStudies', 'patientName', patientName)
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('getStudies', 'uid', uid)
            // verify required parameter 'modality' is not null or undefined
            assertParamExists('getStudies', 'modality', modality)
            // verify required parameter 'category' is not null or undefined
            assertParamExists('getStudies', 'category', category)
            // verify required parameter 'bodyparts' is not null or undefined
            assertParamExists('getStudies', 'bodyparts', bodyparts)
            const localVarPath = `/api/v1/studies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (centreId !== undefined) {
                localVarQueryParameter['centre_id'] = centreId;
            }

            if (radiologistId !== undefined) {
                localVarQueryParameter['radiologist_id'] = radiologistId;
            }

            if (patientName !== undefined) {
                localVarQueryParameter['patient_name'] = patientName;
            }

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }

            if (modality !== undefined) {
                localVarQueryParameter['modality'] = modality;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (bodyparts !== undefined) {
                localVarQueryParameter['bodyparts'] = bodyparts;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (range !== undefined) {
                localVarQueryParameter['range'] = range;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Study
         * @param {number} studyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudy: async (studyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'studyId' is not null or undefined
            assertParamExists('getStudy', 'studyId', studyId)
            const localVarPath = `/api/v1/studies/study/{study_id}`
                .replace(`{${"study_id"}}`, encodeURIComponent(String(studyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sync Study
         * @param {string} fromDate 
         * @param {string} toDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncStudy: async (fromDate: string, toDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fromDate' is not null or undefined
            assertParamExists('syncStudy', 'fromDate', fromDate)
            // verify required parameter 'toDate' is not null or undefined
            assertParamExists('syncStudy', 'toDate', toDate)
            const localVarPath = `/api/v1/studies/sync/nandico/{from_date}/{to_date}`
                .replace(`{${"from_date"}}`, encodeURIComponent(String(fromDate)))
                .replace(`{${"to_date"}}`, encodeURIComponent(String(toDate)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sync Study Range
         * @param {string} fromDate 
         * @param {string} toDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncStudyRange: async (fromDate: string, toDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fromDate' is not null or undefined
            assertParamExists('syncStudyRange', 'fromDate', fromDate)
            // verify required parameter 'toDate' is not null or undefined
            assertParamExists('syncStudyRange', 'toDate', toDate)
            const localVarPath = `/api/v1/studies/sync/nandico/range/{from_date}/{to_date}`
                .replace(`{${"from_date"}}`, encodeURIComponent(String(fromDate)))
                .replace(`{${"to_date"}}`, encodeURIComponent(String(toDate)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sync Study Stradus
         * @param {string} fromDate 
         * @param {string} toDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncStudyStradus: async (fromDate: string, toDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fromDate' is not null or undefined
            assertParamExists('syncStudyStradus', 'fromDate', fromDate)
            // verify required parameter 'toDate' is not null or undefined
            assertParamExists('syncStudyStradus', 'toDate', toDate)
            const localVarPath = `/api/v1/studies/sync/stradus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString().substr(0,10) :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString().substr(0,10) :
                    toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update External Bodypart
         * @param {number} id 
         * @param {string} bodypart 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExternalBodypart: async (id: number, bodypart: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateExternalBodypart', 'id', id)
            // verify required parameter 'bodypart' is not null or undefined
            assertParamExists('updateExternalBodypart', 'bodypart', bodypart)
            const localVarPath = `/api/v1/studies/nandico/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (bodypart !== undefined) {
                localVarQueryParameter['bodypart'] = bodypart;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update External Bodypart Stradus
         * @param {number} id 
         * @param {string} bodypart 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExternalBodypartStradus: async (id: number, bodypart: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateExternalBodypartStradus', 'id', id)
            // verify required parameter 'bodypart' is not null or undefined
            assertParamExists('updateExternalBodypartStradus', 'bodypart', bodypart)
            const localVarPath = `/api/v1/studies/stradus/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (bodypart !== undefined) {
                localVarQueryParameter['bodypart'] = bodypart;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Study
         * @param {number} studyId 
         * @param {StudyUpdateSchema} studyUpdateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStudy: async (studyId: number, studyUpdateSchema: StudyUpdateSchema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'studyId' is not null or undefined
            assertParamExists('updateStudy', 'studyId', studyId)
            // verify required parameter 'studyUpdateSchema' is not null or undefined
            assertParamExists('updateStudy', 'studyUpdateSchema', studyUpdateSchema)
            const localVarPath = `/api/v1/studies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (studyId !== undefined) {
                localVarQueryParameter['study_id'] = studyId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(studyUpdateSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StudyApi - functional programming interface
 * @export
 */
export const StudyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StudyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Study
         * @param {StudyCreateSchema} studyCreateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createStudy(studyCreateSchema: StudyCreateSchema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createStudy(studyCreateSchema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get External Studies
         * @param {string} fromDate 
         * @param {string} toDate 
         * @param {string} status 
         * @param {string} modality 
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExternalStudies(fromDate: string, toDate: string, status: string, modality: string, sort?: string, range?: string, filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExternalStudyListSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExternalStudies(fromDate, toDate, status, modality, sort, range, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get External Studies Stradus
         * @param {string} fromDate 
         * @param {string} toDate 
         * @param {string} status 
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExternalStudiesStradus(fromDate: string, toDate: string, status: string, sort?: string, range?: string, filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExternalStudyListSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExternalStudiesStradus(fromDate, toDate, status, sort, range, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Imaging Centre Zero Charges
         * @param {number} month 
         * @param {number} year 
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getImagingCentreZeroCharges(month: number, year: number, sort?: string, range?: string, filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ZeroChargesSchemaImagingCentre>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getImagingCentreZeroCharges(month, year, sort, range, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Nandico Study Title
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNandicoStudyTitle(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNandicoStudyTitle(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Radiologist Zero Charges
         * @param {number} month 
         * @param {number} year 
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRadiologistZeroCharges(month: number, year: number, sort?: string, range?: string, filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ZeroChargesSchemaRadiologist>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRadiologistZeroCharges(month, year, sort, range, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Stradus Reports
         * @param {string} customerId 
         * @param {string} patientId 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStradusReports(customerId: string, patientId: string, orderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStradusReports(customerId, patientId, orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Studies
         * @param {string} fromDate 
         * @param {string} toDate 
         * @param {number} centreId 
         * @param {number} radiologistId 
         * @param {string} patientName 
         * @param {string} uid 
         * @param {string} modality 
         * @param {string} category 
         * @param {string} bodyparts 
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStudies(fromDate: string, toDate: string, centreId: number, radiologistId: number, patientName: string, uid: string, modality: string, category: string, bodyparts: string, sort?: string, range?: string, filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StudyListSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStudies(fromDate, toDate, centreId, radiologistId, patientName, uid, modality, category, bodyparts, sort, range, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Study
         * @param {number} studyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStudy(studyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudySchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStudy(studyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sync Study
         * @param {string} fromDate 
         * @param {string} toDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncStudy(fromDate: string, toDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncStudy(fromDate, toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sync Study Range
         * @param {string} fromDate 
         * @param {string} toDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncStudyRange(fromDate: string, toDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncStudyRange(fromDate, toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sync Study Stradus
         * @param {string} fromDate 
         * @param {string} toDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncStudyStradus(fromDate: string, toDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncStudyStradus(fromDate, toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update External Bodypart
         * @param {number} id 
         * @param {string} bodypart 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateExternalBodypart(id: number, bodypart: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateExternalBodypart(id, bodypart, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update External Bodypart Stradus
         * @param {number} id 
         * @param {string} bodypart 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateExternalBodypartStradus(id: number, bodypart: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateExternalBodypartStradus(id, bodypart, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Study
         * @param {number} studyId 
         * @param {StudyUpdateSchema} studyUpdateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStudy(studyId: number, studyUpdateSchema: StudyUpdateSchema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateStudy(studyId, studyUpdateSchema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StudyApi - factory interface
 * @export
 */
export const StudyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StudyApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Study
         * @param {StudyCreateSchema} studyCreateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStudy(studyCreateSchema: StudyCreateSchema, options?: any): AxiosPromise<any> {
            return localVarFp.createStudy(studyCreateSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get External Studies
         * @param {string} fromDate 
         * @param {string} toDate 
         * @param {string} status 
         * @param {string} modality 
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExternalStudies(fromDate: string, toDate: string, status: string, modality: string, sort?: string, range?: string, filter?: string, options?: any): AxiosPromise<Array<ExternalStudyListSchema>> {
            return localVarFp.getExternalStudies(fromDate, toDate, status, modality, sort, range, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get External Studies Stradus
         * @param {string} fromDate 
         * @param {string} toDate 
         * @param {string} status 
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExternalStudiesStradus(fromDate: string, toDate: string, status: string, sort?: string, range?: string, filter?: string, options?: any): AxiosPromise<Array<ExternalStudyListSchema>> {
            return localVarFp.getExternalStudiesStradus(fromDate, toDate, status, sort, range, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Imaging Centre Zero Charges
         * @param {number} month 
         * @param {number} year 
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImagingCentreZeroCharges(month: number, year: number, sort?: string, range?: string, filter?: string, options?: any): AxiosPromise<Array<ZeroChargesSchemaImagingCentre>> {
            return localVarFp.getImagingCentreZeroCharges(month, year, sort, range, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Nandico Study Title
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNandicoStudyTitle(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.getNandicoStudyTitle(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Radiologist Zero Charges
         * @param {number} month 
         * @param {number} year 
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRadiologistZeroCharges(month: number, year: number, sort?: string, range?: string, filter?: string, options?: any): AxiosPromise<Array<ZeroChargesSchemaRadiologist>> {
            return localVarFp.getRadiologistZeroCharges(month, year, sort, range, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Stradus Reports
         * @param {string} customerId 
         * @param {string} patientId 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStradusReports(customerId: string, patientId: string, orderId: string, options?: any): AxiosPromise<any> {
            return localVarFp.getStradusReports(customerId, patientId, orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Studies
         * @param {string} fromDate 
         * @param {string} toDate 
         * @param {number} centreId 
         * @param {number} radiologistId 
         * @param {string} patientName 
         * @param {string} uid 
         * @param {string} modality 
         * @param {string} category 
         * @param {string} bodyparts 
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudies(fromDate: string, toDate: string, centreId: number, radiologistId: number, patientName: string, uid: string, modality: string, category: string, bodyparts: string, sort?: string, range?: string, filter?: string, options?: any): AxiosPromise<Array<StudyListSchema>> {
            return localVarFp.getStudies(fromDate, toDate, centreId, radiologistId, patientName, uid, modality, category, bodyparts, sort, range, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Study
         * @param {number} studyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudy(studyId: number, options?: any): AxiosPromise<StudySchema> {
            return localVarFp.getStudy(studyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sync Study
         * @param {string} fromDate 
         * @param {string} toDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncStudy(fromDate: string, toDate: string, options?: any): AxiosPromise<any> {
            return localVarFp.syncStudy(fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sync Study Range
         * @param {string} fromDate 
         * @param {string} toDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncStudyRange(fromDate: string, toDate: string, options?: any): AxiosPromise<any> {
            return localVarFp.syncStudyRange(fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sync Study Stradus
         * @param {string} fromDate 
         * @param {string} toDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncStudyStradus(fromDate: string, toDate: string, options?: any): AxiosPromise<any> {
            return localVarFp.syncStudyStradus(fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update External Bodypart
         * @param {number} id 
         * @param {string} bodypart 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExternalBodypart(id: number, bodypart: string, options?: any): AxiosPromise<any> {
            return localVarFp.updateExternalBodypart(id, bodypart, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update External Bodypart Stradus
         * @param {number} id 
         * @param {string} bodypart 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExternalBodypartStradus(id: number, bodypart: string, options?: any): AxiosPromise<any> {
            return localVarFp.updateExternalBodypartStradus(id, bodypart, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Study
         * @param {number} studyId 
         * @param {StudyUpdateSchema} studyUpdateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStudy(studyId: number, studyUpdateSchema: StudyUpdateSchema, options?: any): AxiosPromise<any> {
            return localVarFp.updateStudy(studyId, studyUpdateSchema, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createStudy operation in StudyApi.
 * @export
 * @interface StudyApiCreateStudyRequest
 */
export interface StudyApiCreateStudyRequest {
    /**
     * 
     * @type {StudyCreateSchema}
     * @memberof StudyApiCreateStudy
     */
    readonly studyCreateSchema: StudyCreateSchema
}

/**
 * Request parameters for getExternalStudies operation in StudyApi.
 * @export
 * @interface StudyApiGetExternalStudiesRequest
 */
export interface StudyApiGetExternalStudiesRequest {
    /**
     * 
     * @type {string}
     * @memberof StudyApiGetExternalStudies
     */
    readonly fromDate: string

    /**
     * 
     * @type {string}
     * @memberof StudyApiGetExternalStudies
     */
    readonly toDate: string

    /**
     * 
     * @type {string}
     * @memberof StudyApiGetExternalStudies
     */
    readonly status: string

    /**
     * 
     * @type {string}
     * @memberof StudyApiGetExternalStudies
     */
    readonly modality: string

    /**
     * Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
     * @type {string}
     * @memberof StudyApiGetExternalStudies
     */
    readonly sort?: string

    /**
     * Format: &#x60;[start, end]&#x60;
     * @type {string}
     * @memberof StudyApiGetExternalStudies
     */
    readonly range?: string

    /**
     * Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
     * @type {string}
     * @memberof StudyApiGetExternalStudies
     */
    readonly filter?: string
}

/**
 * Request parameters for getExternalStudiesStradus operation in StudyApi.
 * @export
 * @interface StudyApiGetExternalStudiesStradusRequest
 */
export interface StudyApiGetExternalStudiesStradusRequest {
    /**
     * 
     * @type {string}
     * @memberof StudyApiGetExternalStudiesStradus
     */
    readonly fromDate: string

    /**
     * 
     * @type {string}
     * @memberof StudyApiGetExternalStudiesStradus
     */
    readonly toDate: string

    /**
     * 
     * @type {string}
     * @memberof StudyApiGetExternalStudiesStradus
     */
    readonly status: string

    /**
     * Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
     * @type {string}
     * @memberof StudyApiGetExternalStudiesStradus
     */
    readonly sort?: string

    /**
     * Format: &#x60;[start, end]&#x60;
     * @type {string}
     * @memberof StudyApiGetExternalStudiesStradus
     */
    readonly range?: string

    /**
     * Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
     * @type {string}
     * @memberof StudyApiGetExternalStudiesStradus
     */
    readonly filter?: string
}

/**
 * Request parameters for getImagingCentreZeroCharges operation in StudyApi.
 * @export
 * @interface StudyApiGetImagingCentreZeroChargesRequest
 */
export interface StudyApiGetImagingCentreZeroChargesRequest {
    /**
     * 
     * @type {number}
     * @memberof StudyApiGetImagingCentreZeroCharges
     */
    readonly month: number

    /**
     * 
     * @type {number}
     * @memberof StudyApiGetImagingCentreZeroCharges
     */
    readonly year: number

    /**
     * Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
     * @type {string}
     * @memberof StudyApiGetImagingCentreZeroCharges
     */
    readonly sort?: string

    /**
     * Format: &#x60;[start, end]&#x60;
     * @type {string}
     * @memberof StudyApiGetImagingCentreZeroCharges
     */
    readonly range?: string

    /**
     * Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
     * @type {string}
     * @memberof StudyApiGetImagingCentreZeroCharges
     */
    readonly filter?: string
}

/**
 * Request parameters for getNandicoStudyTitle operation in StudyApi.
 * @export
 * @interface StudyApiGetNandicoStudyTitleRequest
 */
export interface StudyApiGetNandicoStudyTitleRequest {
    /**
     * 
     * @type {number}
     * @memberof StudyApiGetNandicoStudyTitle
     */
    readonly id: number
}

/**
 * Request parameters for getRadiologistZeroCharges operation in StudyApi.
 * @export
 * @interface StudyApiGetRadiologistZeroChargesRequest
 */
export interface StudyApiGetRadiologistZeroChargesRequest {
    /**
     * 
     * @type {number}
     * @memberof StudyApiGetRadiologistZeroCharges
     */
    readonly month: number

    /**
     * 
     * @type {number}
     * @memberof StudyApiGetRadiologistZeroCharges
     */
    readonly year: number

    /**
     * Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
     * @type {string}
     * @memberof StudyApiGetRadiologistZeroCharges
     */
    readonly sort?: string

    /**
     * Format: &#x60;[start, end]&#x60;
     * @type {string}
     * @memberof StudyApiGetRadiologistZeroCharges
     */
    readonly range?: string

    /**
     * Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
     * @type {string}
     * @memberof StudyApiGetRadiologistZeroCharges
     */
    readonly filter?: string
}

/**
 * Request parameters for getStradusReports operation in StudyApi.
 * @export
 * @interface StudyApiGetStradusReportsRequest
 */
export interface StudyApiGetStradusReportsRequest {
    /**
     * 
     * @type {string}
     * @memberof StudyApiGetStradusReports
     */
    readonly customerId: string

    /**
     * 
     * @type {string}
     * @memberof StudyApiGetStradusReports
     */
    readonly patientId: string

    /**
     * 
     * @type {string}
     * @memberof StudyApiGetStradusReports
     */
    readonly orderId: string
}

/**
 * Request parameters for getStudies operation in StudyApi.
 * @export
 * @interface StudyApiGetStudiesRequest
 */
export interface StudyApiGetStudiesRequest {
    /**
     * 
     * @type {string}
     * @memberof StudyApiGetStudies
     */
    readonly fromDate: string

    /**
     * 
     * @type {string}
     * @memberof StudyApiGetStudies
     */
    readonly toDate: string

    /**
     * 
     * @type {number}
     * @memberof StudyApiGetStudies
     */
    readonly centreId: number

    /**
     * 
     * @type {number}
     * @memberof StudyApiGetStudies
     */
    readonly radiologistId: number

    /**
     * 
     * @type {string}
     * @memberof StudyApiGetStudies
     */
    readonly patientName: string

    /**
     * 
     * @type {string}
     * @memberof StudyApiGetStudies
     */
    readonly uid: string

    /**
     * 
     * @type {string}
     * @memberof StudyApiGetStudies
     */
    readonly modality: string

    /**
     * 
     * @type {string}
     * @memberof StudyApiGetStudies
     */
    readonly category: string

    /**
     * 
     * @type {string}
     * @memberof StudyApiGetStudies
     */
    readonly bodyparts: string

    /**
     * Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
     * @type {string}
     * @memberof StudyApiGetStudies
     */
    readonly sort?: string

    /**
     * Format: &#x60;[start, end]&#x60;
     * @type {string}
     * @memberof StudyApiGetStudies
     */
    readonly range?: string

    /**
     * Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
     * @type {string}
     * @memberof StudyApiGetStudies
     */
    readonly filter?: string
}

/**
 * Request parameters for getStudy operation in StudyApi.
 * @export
 * @interface StudyApiGetStudyRequest
 */
export interface StudyApiGetStudyRequest {
    /**
     * 
     * @type {number}
     * @memberof StudyApiGetStudy
     */
    readonly studyId: number
}

/**
 * Request parameters for syncStudy operation in StudyApi.
 * @export
 * @interface StudyApiSyncStudyRequest
 */
export interface StudyApiSyncStudyRequest {
    /**
     * 
     * @type {string}
     * @memberof StudyApiSyncStudy
     */
    readonly fromDate: string

    /**
     * 
     * @type {string}
     * @memberof StudyApiSyncStudy
     */
    readonly toDate: string
}

/**
 * Request parameters for syncStudyRange operation in StudyApi.
 * @export
 * @interface StudyApiSyncStudyRangeRequest
 */
export interface StudyApiSyncStudyRangeRequest {
    /**
     * 
     * @type {string}
     * @memberof StudyApiSyncStudyRange
     */
    readonly fromDate: string

    /**
     * 
     * @type {string}
     * @memberof StudyApiSyncStudyRange
     */
    readonly toDate: string
}

/**
 * Request parameters for syncStudyStradus operation in StudyApi.
 * @export
 * @interface StudyApiSyncStudyStradusRequest
 */
export interface StudyApiSyncStudyStradusRequest {
    /**
     * 
     * @type {string}
     * @memberof StudyApiSyncStudyStradus
     */
    readonly fromDate: string

    /**
     * 
     * @type {string}
     * @memberof StudyApiSyncStudyStradus
     */
    readonly toDate: string
}

/**
 * Request parameters for updateExternalBodypart operation in StudyApi.
 * @export
 * @interface StudyApiUpdateExternalBodypartRequest
 */
export interface StudyApiUpdateExternalBodypartRequest {
    /**
     * 
     * @type {number}
     * @memberof StudyApiUpdateExternalBodypart
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof StudyApiUpdateExternalBodypart
     */
    readonly bodypart: string
}

/**
 * Request parameters for updateExternalBodypartStradus operation in StudyApi.
 * @export
 * @interface StudyApiUpdateExternalBodypartStradusRequest
 */
export interface StudyApiUpdateExternalBodypartStradusRequest {
    /**
     * 
     * @type {number}
     * @memberof StudyApiUpdateExternalBodypartStradus
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof StudyApiUpdateExternalBodypartStradus
     */
    readonly bodypart: string
}

/**
 * Request parameters for updateStudy operation in StudyApi.
 * @export
 * @interface StudyApiUpdateStudyRequest
 */
export interface StudyApiUpdateStudyRequest {
    /**
     * 
     * @type {number}
     * @memberof StudyApiUpdateStudy
     */
    readonly studyId: number

    /**
     * 
     * @type {StudyUpdateSchema}
     * @memberof StudyApiUpdateStudy
     */
    readonly studyUpdateSchema: StudyUpdateSchema
}

/**
 * StudyApi - object-oriented interface
 * @export
 * @class StudyApi
 * @extends {BaseAPI}
 */
export class StudyApi extends BaseAPI {
    /**
     * 
     * @summary Create Study
     * @param {StudyApiCreateStudyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudyApi
     */
    public createStudy(requestParameters: StudyApiCreateStudyRequest, options?: AxiosRequestConfig) {
        return StudyApiFp(this.configuration).createStudy(requestParameters.studyCreateSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get External Studies
     * @param {StudyApiGetExternalStudiesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudyApi
     */
    public getExternalStudies(requestParameters: StudyApiGetExternalStudiesRequest, options?: AxiosRequestConfig) {
        return StudyApiFp(this.configuration).getExternalStudies(requestParameters.fromDate, requestParameters.toDate, requestParameters.status, requestParameters.modality, requestParameters.sort, requestParameters.range, requestParameters.filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get External Studies Stradus
     * @param {StudyApiGetExternalStudiesStradusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudyApi
     */
    public getExternalStudiesStradus(requestParameters: StudyApiGetExternalStudiesStradusRequest, options?: AxiosRequestConfig) {
        return StudyApiFp(this.configuration).getExternalStudiesStradus(requestParameters.fromDate, requestParameters.toDate, requestParameters.status, requestParameters.sort, requestParameters.range, requestParameters.filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Imaging Centre Zero Charges
     * @param {StudyApiGetImagingCentreZeroChargesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudyApi
     */
    public getImagingCentreZeroCharges(requestParameters: StudyApiGetImagingCentreZeroChargesRequest, options?: AxiosRequestConfig) {
        return StudyApiFp(this.configuration).getImagingCentreZeroCharges(requestParameters.month, requestParameters.year, requestParameters.sort, requestParameters.range, requestParameters.filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Nandico Study Title
     * @param {StudyApiGetNandicoStudyTitleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudyApi
     */
    public getNandicoStudyTitle(requestParameters: StudyApiGetNandicoStudyTitleRequest, options?: AxiosRequestConfig) {
        return StudyApiFp(this.configuration).getNandicoStudyTitle(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Radiologist Zero Charges
     * @param {StudyApiGetRadiologistZeroChargesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudyApi
     */
    public getRadiologistZeroCharges(requestParameters: StudyApiGetRadiologistZeroChargesRequest, options?: AxiosRequestConfig) {
        return StudyApiFp(this.configuration).getRadiologistZeroCharges(requestParameters.month, requestParameters.year, requestParameters.sort, requestParameters.range, requestParameters.filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Stradus Reports
     * @param {StudyApiGetStradusReportsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudyApi
     */
    public getStradusReports(requestParameters: StudyApiGetStradusReportsRequest, options?: AxiosRequestConfig) {
        return StudyApiFp(this.configuration).getStradusReports(requestParameters.customerId, requestParameters.patientId, requestParameters.orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Studies
     * @param {StudyApiGetStudiesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudyApi
     */
    public getStudies(requestParameters: StudyApiGetStudiesRequest, options?: AxiosRequestConfig) {
        return StudyApiFp(this.configuration).getStudies(requestParameters.fromDate, requestParameters.toDate, requestParameters.centreId, requestParameters.radiologistId, requestParameters.patientName, requestParameters.uid, requestParameters.modality, requestParameters.category, requestParameters.bodyparts, requestParameters.sort, requestParameters.range, requestParameters.filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Study
     * @param {StudyApiGetStudyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudyApi
     */
    public getStudy(requestParameters: StudyApiGetStudyRequest, options?: AxiosRequestConfig) {
        return StudyApiFp(this.configuration).getStudy(requestParameters.studyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sync Study
     * @param {StudyApiSyncStudyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudyApi
     */
    public syncStudy(requestParameters: StudyApiSyncStudyRequest, options?: AxiosRequestConfig) {
        return StudyApiFp(this.configuration).syncStudy(requestParameters.fromDate, requestParameters.toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sync Study Range
     * @param {StudyApiSyncStudyRangeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudyApi
     */
    public syncStudyRange(requestParameters: StudyApiSyncStudyRangeRequest, options?: AxiosRequestConfig) {
        return StudyApiFp(this.configuration).syncStudyRange(requestParameters.fromDate, requestParameters.toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sync Study Stradus
     * @param {StudyApiSyncStudyStradusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudyApi
     */
    public syncStudyStradus(requestParameters: StudyApiSyncStudyStradusRequest, options?: AxiosRequestConfig) {
        return StudyApiFp(this.configuration).syncStudyStradus(requestParameters.fromDate, requestParameters.toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update External Bodypart
     * @param {StudyApiUpdateExternalBodypartRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudyApi
     */
    public updateExternalBodypart(requestParameters: StudyApiUpdateExternalBodypartRequest, options?: AxiosRequestConfig) {
        return StudyApiFp(this.configuration).updateExternalBodypart(requestParameters.id, requestParameters.bodypart, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update External Bodypart Stradus
     * @param {StudyApiUpdateExternalBodypartStradusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudyApi
     */
    public updateExternalBodypartStradus(requestParameters: StudyApiUpdateExternalBodypartStradusRequest, options?: AxiosRequestConfig) {
        return StudyApiFp(this.configuration).updateExternalBodypartStradus(requestParameters.id, requestParameters.bodypart, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Study
     * @param {StudyApiUpdateStudyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudyApi
     */
    public updateStudy(requestParameters: StudyApiUpdateStudyRequest, options?: AxiosRequestConfig) {
        return StudyApiFp(this.configuration).updateStudy(requestParameters.studyId, requestParameters.studyUpdateSchema, options).then((request) => request(this.axios, this.basePath));
    }
}

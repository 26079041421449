/* tslint:disable */
/* eslint-disable */
/**
 * eminence-pac-system
 * eminence-pac-system API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { HTTPValidationError } from '../models';
// @ts-ignore
import { RadiologistBillingList } from '../models';
/**
 * BillingApi - axios parameter creator
 * @export
 */
export const BillingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancel Radiologist Billings
         * @param {number} month 
         * @param {number} year 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelRadiologistBillings: async (month: number, year: number, requestBody: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'month' is not null or undefined
            assertParamExists('cancelRadiologistBillings', 'month', month)
            // verify required parameter 'year' is not null or undefined
            assertParamExists('cancelRadiologistBillings', 'year', year)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('cancelRadiologistBillings', 'requestBody', requestBody)
            const localVarPath = `/api/v1/billingradiologist/cancel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate Radiologist Billings
         * @param {number} month 
         * @param {number} year 
         * @param {boolean} sendEmails 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateRadiologistBillings: async (month: number, year: number, sendEmails: boolean, requestBody: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'month' is not null or undefined
            assertParamExists('generateRadiologistBillings', 'month', month)
            // verify required parameter 'year' is not null or undefined
            assertParamExists('generateRadiologistBillings', 'year', year)
            // verify required parameter 'sendEmails' is not null or undefined
            assertParamExists('generateRadiologistBillings', 'sendEmails', sendEmails)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('generateRadiologistBillings', 'requestBody', requestBody)
            const localVarPath = `/api/v1/billinggenerate/radiologist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (sendEmails !== undefined) {
                localVarQueryParameter['sendEmails'] = sendEmails;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Radiologist Billing List
         * @param {number} month 
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRadiologistBillingList: async (month: number, year: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'month' is not null or undefined
            assertParamExists('getRadiologistBillingList', 'month', month)
            // verify required parameter 'year' is not null or undefined
            assertParamExists('getRadiologistBillingList', 'year', year)
            const localVarPath = `/api/v1/billing/radiologist/{month}/{year}`
                .replace(`{${"month"}}`, encodeURIComponent(String(month)))
                .replace(`{${"year"}}`, encodeURIComponent(String(year)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BillingApi - functional programming interface
 * @export
 */
export const BillingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BillingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Cancel Radiologist Billings
         * @param {number} month 
         * @param {number} year 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelRadiologistBillings(month: number, year: number, requestBody: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelRadiologistBillings(month, year, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Generate Radiologist Billings
         * @param {number} month 
         * @param {number} year 
         * @param {boolean} sendEmails 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateRadiologistBillings(month: number, year: number, sendEmails: boolean, requestBody: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateRadiologistBillings(month, year, sendEmails, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Radiologist Billing List
         * @param {number} month 
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRadiologistBillingList(month: number, year: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RadiologistBillingList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRadiologistBillingList(month, year, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BillingApi - factory interface
 * @export
 */
export const BillingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BillingApiFp(configuration)
    return {
        /**
         * 
         * @summary Cancel Radiologist Billings
         * @param {number} month 
         * @param {number} year 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelRadiologistBillings(month: number, year: number, requestBody: Array<number>, options?: any): AxiosPromise<any> {
            return localVarFp.cancelRadiologistBillings(month, year, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generate Radiologist Billings
         * @param {number} month 
         * @param {number} year 
         * @param {boolean} sendEmails 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateRadiologistBillings(month: number, year: number, sendEmails: boolean, requestBody: Array<number>, options?: any): AxiosPromise<any> {
            return localVarFp.generateRadiologistBillings(month, year, sendEmails, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Radiologist Billing List
         * @param {number} month 
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRadiologistBillingList(month: number, year: number, options?: any): AxiosPromise<Array<RadiologistBillingList>> {
            return localVarFp.getRadiologistBillingList(month, year, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for cancelRadiologistBillings operation in BillingApi.
 * @export
 * @interface BillingApiCancelRadiologistBillingsRequest
 */
export interface BillingApiCancelRadiologistBillingsRequest {
    /**
     * 
     * @type {number}
     * @memberof BillingApiCancelRadiologistBillings
     */
    readonly month: number

    /**
     * 
     * @type {number}
     * @memberof BillingApiCancelRadiologistBillings
     */
    readonly year: number

    /**
     * 
     * @type {Array<number>}
     * @memberof BillingApiCancelRadiologistBillings
     */
    readonly requestBody: Array<number>
}

/**
 * Request parameters for generateRadiologistBillings operation in BillingApi.
 * @export
 * @interface BillingApiGenerateRadiologistBillingsRequest
 */
export interface BillingApiGenerateRadiologistBillingsRequest {
    /**
     * 
     * @type {number}
     * @memberof BillingApiGenerateRadiologistBillings
     */
    readonly month: number

    /**
     * 
     * @type {number}
     * @memberof BillingApiGenerateRadiologistBillings
     */
    readonly year: number

    /**
     * 
     * @type {boolean}
     * @memberof BillingApiGenerateRadiologistBillings
     */
    readonly sendEmails: boolean

    /**
     * 
     * @type {Array<number>}
     * @memberof BillingApiGenerateRadiologistBillings
     */
    readonly requestBody: Array<number>
}

/**
 * Request parameters for getRadiologistBillingList operation in BillingApi.
 * @export
 * @interface BillingApiGetRadiologistBillingListRequest
 */
export interface BillingApiGetRadiologistBillingListRequest {
    /**
     * 
     * @type {number}
     * @memberof BillingApiGetRadiologistBillingList
     */
    readonly month: number

    /**
     * 
     * @type {number}
     * @memberof BillingApiGetRadiologistBillingList
     */
    readonly year: number
}

/**
 * BillingApi - object-oriented interface
 * @export
 * @class BillingApi
 * @extends {BaseAPI}
 */
export class BillingApi extends BaseAPI {
    /**
     * 
     * @summary Cancel Radiologist Billings
     * @param {BillingApiCancelRadiologistBillingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public cancelRadiologistBillings(requestParameters: BillingApiCancelRadiologistBillingsRequest, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).cancelRadiologistBillings(requestParameters.month, requestParameters.year, requestParameters.requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generate Radiologist Billings
     * @param {BillingApiGenerateRadiologistBillingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public generateRadiologistBillings(requestParameters: BillingApiGenerateRadiologistBillingsRequest, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).generateRadiologistBillings(requestParameters.month, requestParameters.year, requestParameters.sendEmails, requestParameters.requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Radiologist Billing List
     * @param {BillingApiGetRadiologistBillingListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public getRadiologistBillingList(requestParameters: BillingApiGetRadiologistBillingListRequest, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).getRadiologistBillingList(requestParameters.month, requestParameters.year, options).then((request) => request(this.axios, this.basePath));
    }
}

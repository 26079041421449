/* tslint:disable */
/* eslint-disable */
/**
 * eminence-pac-system
 * eminence-pac-system API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { HTTPValidationError } from '../models';
// @ts-ignore
import { ImagingCentreExtraCharges } from '../models';
// @ts-ignore
import { ImagingCentreExtraChargesCreate } from '../models';
// @ts-ignore
import { ImagingCentreExtraChargesUpdate } from '../models';
/**
 * ImagingCentreExtraChargesApi - axios parameter creator
 * @export
 */
export const ImagingCentreExtraChargesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Imaging Centre Extra Charge
         * @param {ImagingCentreExtraChargesCreate} imagingCentreExtraChargesCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createImagingCentreExtraCharge: async (imagingCentreExtraChargesCreate: ImagingCentreExtraChargesCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'imagingCentreExtraChargesCreate' is not null or undefined
            assertParamExists('createImagingCentreExtraCharge', 'imagingCentreExtraChargesCreate', imagingCentreExtraChargesCreate)
            const localVarPath = `/api/v1/imaging-centre/extra-charges`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(imagingCentreExtraChargesCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Imaging Centre Extra Charge
         * @param {number} extraChargeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImagingCentreExtraCharge: async (extraChargeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'extraChargeId' is not null or undefined
            assertParamExists('deleteImagingCentreExtraCharge', 'extraChargeId', extraChargeId)
            const localVarPath = `/api/v1/imaging-centre/extra-charges/{extra_charge_id}`
                .replace(`{${"extra_charge_id"}}`, encodeURIComponent(String(extraChargeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Imaging Centre Extra Charges
         * @param {number} imagingCentreId 
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImagingCentreExtraCharges: async (imagingCentreId: number, sort?: string, range?: string, filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'imagingCentreId' is not null or undefined
            assertParamExists('getImagingCentreExtraCharges', 'imagingCentreId', imagingCentreId)
            const localVarPath = `/api/v1/imaging-centre/{imaging_centre_id}/extra-charges`
                .replace(`{${"imaging_centre_id"}}`, encodeURIComponent(String(imagingCentreId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (range !== undefined) {
                localVarQueryParameter['range'] = range;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Imaging Centre Extra Charge
         * @param {number} extraChargeId 
         * @param {ImagingCentreExtraChargesUpdate} imagingCentreExtraChargesUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateImagingCentreExtraCharge: async (extraChargeId: number, imagingCentreExtraChargesUpdate: ImagingCentreExtraChargesUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'extraChargeId' is not null or undefined
            assertParamExists('updateImagingCentreExtraCharge', 'extraChargeId', extraChargeId)
            // verify required parameter 'imagingCentreExtraChargesUpdate' is not null or undefined
            assertParamExists('updateImagingCentreExtraCharge', 'imagingCentreExtraChargesUpdate', imagingCentreExtraChargesUpdate)
            const localVarPath = `/api/v1/imaging-centre/extra-charges/{extra_charge_id}`
                .replace(`{${"extra_charge_id"}}`, encodeURIComponent(String(extraChargeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(imagingCentreExtraChargesUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ImagingCentreExtraChargesApi - functional programming interface
 * @export
 */
export const ImagingCentreExtraChargesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ImagingCentreExtraChargesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Imaging Centre Extra Charge
         * @param {ImagingCentreExtraChargesCreate} imagingCentreExtraChargesCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createImagingCentreExtraCharge(imagingCentreExtraChargesCreate: ImagingCentreExtraChargesCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImagingCentreExtraCharges>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createImagingCentreExtraCharge(imagingCentreExtraChargesCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Imaging Centre Extra Charge
         * @param {number} extraChargeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteImagingCentreExtraCharge(extraChargeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteImagingCentreExtraCharge(extraChargeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Imaging Centre Extra Charges
         * @param {number} imagingCentreId 
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getImagingCentreExtraCharges(imagingCentreId: number, sort?: string, range?: string, filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ImagingCentreExtraCharges>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getImagingCentreExtraCharges(imagingCentreId, sort, range, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Imaging Centre Extra Charge
         * @param {number} extraChargeId 
         * @param {ImagingCentreExtraChargesUpdate} imagingCentreExtraChargesUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateImagingCentreExtraCharge(extraChargeId: number, imagingCentreExtraChargesUpdate: ImagingCentreExtraChargesUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImagingCentreExtraCharges>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateImagingCentreExtraCharge(extraChargeId, imagingCentreExtraChargesUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ImagingCentreExtraChargesApi - factory interface
 * @export
 */
export const ImagingCentreExtraChargesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ImagingCentreExtraChargesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Imaging Centre Extra Charge
         * @param {ImagingCentreExtraChargesCreate} imagingCentreExtraChargesCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createImagingCentreExtraCharge(imagingCentreExtraChargesCreate: ImagingCentreExtraChargesCreate, options?: any): AxiosPromise<ImagingCentreExtraCharges> {
            return localVarFp.createImagingCentreExtraCharge(imagingCentreExtraChargesCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Imaging Centre Extra Charge
         * @param {number} extraChargeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImagingCentreExtraCharge(extraChargeId: number, options?: any): AxiosPromise<any> {
            return localVarFp.deleteImagingCentreExtraCharge(extraChargeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Imaging Centre Extra Charges
         * @param {number} imagingCentreId 
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImagingCentreExtraCharges(imagingCentreId: number, sort?: string, range?: string, filter?: string, options?: any): AxiosPromise<Array<ImagingCentreExtraCharges>> {
            return localVarFp.getImagingCentreExtraCharges(imagingCentreId, sort, range, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Imaging Centre Extra Charge
         * @param {number} extraChargeId 
         * @param {ImagingCentreExtraChargesUpdate} imagingCentreExtraChargesUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateImagingCentreExtraCharge(extraChargeId: number, imagingCentreExtraChargesUpdate: ImagingCentreExtraChargesUpdate, options?: any): AxiosPromise<ImagingCentreExtraCharges> {
            return localVarFp.updateImagingCentreExtraCharge(extraChargeId, imagingCentreExtraChargesUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createImagingCentreExtraCharge operation in ImagingCentreExtraChargesApi.
 * @export
 * @interface ImagingCentreExtraChargesApiCreateImagingCentreExtraChargeRequest
 */
export interface ImagingCentreExtraChargesApiCreateImagingCentreExtraChargeRequest {
    /**
     * 
     * @type {ImagingCentreExtraChargesCreate}
     * @memberof ImagingCentreExtraChargesApiCreateImagingCentreExtraCharge
     */
    readonly imagingCentreExtraChargesCreate: ImagingCentreExtraChargesCreate
}

/**
 * Request parameters for deleteImagingCentreExtraCharge operation in ImagingCentreExtraChargesApi.
 * @export
 * @interface ImagingCentreExtraChargesApiDeleteImagingCentreExtraChargeRequest
 */
export interface ImagingCentreExtraChargesApiDeleteImagingCentreExtraChargeRequest {
    /**
     * 
     * @type {number}
     * @memberof ImagingCentreExtraChargesApiDeleteImagingCentreExtraCharge
     */
    readonly extraChargeId: number
}

/**
 * Request parameters for getImagingCentreExtraCharges operation in ImagingCentreExtraChargesApi.
 * @export
 * @interface ImagingCentreExtraChargesApiGetImagingCentreExtraChargesRequest
 */
export interface ImagingCentreExtraChargesApiGetImagingCentreExtraChargesRequest {
    /**
     * 
     * @type {number}
     * @memberof ImagingCentreExtraChargesApiGetImagingCentreExtraCharges
     */
    readonly imagingCentreId: number

    /**
     * Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
     * @type {string}
     * @memberof ImagingCentreExtraChargesApiGetImagingCentreExtraCharges
     */
    readonly sort?: string

    /**
     * Format: &#x60;[start, end]&#x60;
     * @type {string}
     * @memberof ImagingCentreExtraChargesApiGetImagingCentreExtraCharges
     */
    readonly range?: string

    /**
     * Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
     * @type {string}
     * @memberof ImagingCentreExtraChargesApiGetImagingCentreExtraCharges
     */
    readonly filter?: string
}

/**
 * Request parameters for updateImagingCentreExtraCharge operation in ImagingCentreExtraChargesApi.
 * @export
 * @interface ImagingCentreExtraChargesApiUpdateImagingCentreExtraChargeRequest
 */
export interface ImagingCentreExtraChargesApiUpdateImagingCentreExtraChargeRequest {
    /**
     * 
     * @type {number}
     * @memberof ImagingCentreExtraChargesApiUpdateImagingCentreExtraCharge
     */
    readonly extraChargeId: number

    /**
     * 
     * @type {ImagingCentreExtraChargesUpdate}
     * @memberof ImagingCentreExtraChargesApiUpdateImagingCentreExtraCharge
     */
    readonly imagingCentreExtraChargesUpdate: ImagingCentreExtraChargesUpdate
}

/**
 * ImagingCentreExtraChargesApi - object-oriented interface
 * @export
 * @class ImagingCentreExtraChargesApi
 * @extends {BaseAPI}
 */
export class ImagingCentreExtraChargesApi extends BaseAPI {
    /**
     * 
     * @summary Create Imaging Centre Extra Charge
     * @param {ImagingCentreExtraChargesApiCreateImagingCentreExtraChargeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagingCentreExtraChargesApi
     */
    public createImagingCentreExtraCharge(requestParameters: ImagingCentreExtraChargesApiCreateImagingCentreExtraChargeRequest, options?: AxiosRequestConfig) {
        return ImagingCentreExtraChargesApiFp(this.configuration).createImagingCentreExtraCharge(requestParameters.imagingCentreExtraChargesCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Imaging Centre Extra Charge
     * @param {ImagingCentreExtraChargesApiDeleteImagingCentreExtraChargeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagingCentreExtraChargesApi
     */
    public deleteImagingCentreExtraCharge(requestParameters: ImagingCentreExtraChargesApiDeleteImagingCentreExtraChargeRequest, options?: AxiosRequestConfig) {
        return ImagingCentreExtraChargesApiFp(this.configuration).deleteImagingCentreExtraCharge(requestParameters.extraChargeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Imaging Centre Extra Charges
     * @param {ImagingCentreExtraChargesApiGetImagingCentreExtraChargesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagingCentreExtraChargesApi
     */
    public getImagingCentreExtraCharges(requestParameters: ImagingCentreExtraChargesApiGetImagingCentreExtraChargesRequest, options?: AxiosRequestConfig) {
        return ImagingCentreExtraChargesApiFp(this.configuration).getImagingCentreExtraCharges(requestParameters.imagingCentreId, requestParameters.sort, requestParameters.range, requestParameters.filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Imaging Centre Extra Charge
     * @param {ImagingCentreExtraChargesApiUpdateImagingCentreExtraChargeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagingCentreExtraChargesApi
     */
    public updateImagingCentreExtraCharge(requestParameters: ImagingCentreExtraChargesApiUpdateImagingCentreExtraChargeRequest, options?: AxiosRequestConfig) {
        return ImagingCentreExtraChargesApiFp(this.configuration).updateImagingCentreExtraCharge(requestParameters.extraChargeId, requestParameters.imagingCentreExtraChargesUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}

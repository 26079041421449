/* tslint:disable */
/* eslint-disable */
/**
 * eminence-pac-system
 * eminence-pac-system API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { HTTPValidationError } from '../models';
// @ts-ignore
import { RadiologistGradeCharges } from '../models';
// @ts-ignore
import { RadiologistGradeChargesCreate } from '../models';
// @ts-ignore
import { RadiologistGradeChargesUpdate } from '../models';
/**
 * RadiologistGradeChargesApi - axios parameter creator
 * @export
 */
export const RadiologistGradeChargesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Radiologist Grade Charge
         * @param {RadiologistGradeChargesCreate} radiologistGradeChargesCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRadiologistGradeCharge: async (radiologistGradeChargesCreate: RadiologistGradeChargesCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'radiologistGradeChargesCreate' is not null or undefined
            assertParamExists('createRadiologistGradeCharge', 'radiologistGradeChargesCreate', radiologistGradeChargesCreate)
            const localVarPath = `/api/v1/radiologist/grade/charges`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(radiologistGradeChargesCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Radiologist Grade Charges Bulk
         * @param {Array<RadiologistGradeChargesCreate>} radiologistGradeChargesCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRadiologistGradeChargesBulk: async (radiologistGradeChargesCreate: Array<RadiologistGradeChargesCreate>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'radiologistGradeChargesCreate' is not null or undefined
            assertParamExists('createRadiologistGradeChargesBulk', 'radiologistGradeChargesCreate', radiologistGradeChargesCreate)
            const localVarPath = `/api/v1/radiologist/grade/charges/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(radiologistGradeChargesCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Radiologist Grade Charge
         * @param {number} chargeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRadiologistGradeCharge: async (chargeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chargeId' is not null or undefined
            assertParamExists('deleteRadiologistGradeCharge', 'chargeId', chargeId)
            const localVarPath = `/api/v1/radiologist/grade/charges/{charge_id}`
                .replace(`{${"charge_id"}}`, encodeURIComponent(String(chargeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Radiologist Grade Charges
         * @param {number} gradeId 
         * @param {string} modality 
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRadiologistGradeCharges: async (gradeId: number, modality: string, sort?: string, range?: string, filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gradeId' is not null or undefined
            assertParamExists('getRadiologistGradeCharges', 'gradeId', gradeId)
            // verify required parameter 'modality' is not null or undefined
            assertParamExists('getRadiologistGradeCharges', 'modality', modality)
            const localVarPath = `/api/v1/radiologist/grade/{grade_id}/charges`
                .replace(`{${"grade_id"}}`, encodeURIComponent(String(gradeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (modality !== undefined) {
                localVarQueryParameter['modality'] = modality;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (range !== undefined) {
                localVarQueryParameter['range'] = range;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Radiologist Grade Charge
         * @param {number} chargeId 
         * @param {RadiologistGradeChargesUpdate} radiologistGradeChargesUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRadiologistGradeCharge: async (chargeId: number, radiologistGradeChargesUpdate: RadiologistGradeChargesUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chargeId' is not null or undefined
            assertParamExists('updateRadiologistGradeCharge', 'chargeId', chargeId)
            // verify required parameter 'radiologistGradeChargesUpdate' is not null or undefined
            assertParamExists('updateRadiologistGradeCharge', 'radiologistGradeChargesUpdate', radiologistGradeChargesUpdate)
            const localVarPath = `/api/v1/radiologist/grade/charges/{charge_id}`
                .replace(`{${"charge_id"}}`, encodeURIComponent(String(chargeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(radiologistGradeChargesUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RadiologistGradeChargesApi - functional programming interface
 * @export
 */
export const RadiologistGradeChargesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RadiologistGradeChargesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Radiologist Grade Charge
         * @param {RadiologistGradeChargesCreate} radiologistGradeChargesCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRadiologistGradeCharge(radiologistGradeChargesCreate: RadiologistGradeChargesCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RadiologistGradeCharges>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRadiologistGradeCharge(radiologistGradeChargesCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Radiologist Grade Charges Bulk
         * @param {Array<RadiologistGradeChargesCreate>} radiologistGradeChargesCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRadiologistGradeChargesBulk(radiologistGradeChargesCreate: Array<RadiologistGradeChargesCreate>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRadiologistGradeChargesBulk(radiologistGradeChargesCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Radiologist Grade Charge
         * @param {number} chargeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRadiologistGradeCharge(chargeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRadiologistGradeCharge(chargeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Radiologist Grade Charges
         * @param {number} gradeId 
         * @param {string} modality 
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRadiologistGradeCharges(gradeId: number, modality: string, sort?: string, range?: string, filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RadiologistGradeCharges>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRadiologistGradeCharges(gradeId, modality, sort, range, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Radiologist Grade Charge
         * @param {number} chargeId 
         * @param {RadiologistGradeChargesUpdate} radiologistGradeChargesUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRadiologistGradeCharge(chargeId: number, radiologistGradeChargesUpdate: RadiologistGradeChargesUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RadiologistGradeCharges>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRadiologistGradeCharge(chargeId, radiologistGradeChargesUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RadiologistGradeChargesApi - factory interface
 * @export
 */
export const RadiologistGradeChargesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RadiologistGradeChargesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Radiologist Grade Charge
         * @param {RadiologistGradeChargesCreate} radiologistGradeChargesCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRadiologistGradeCharge(radiologistGradeChargesCreate: RadiologistGradeChargesCreate, options?: any): AxiosPromise<RadiologistGradeCharges> {
            return localVarFp.createRadiologistGradeCharge(radiologistGradeChargesCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Radiologist Grade Charges Bulk
         * @param {Array<RadiologistGradeChargesCreate>} radiologistGradeChargesCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRadiologistGradeChargesBulk(radiologistGradeChargesCreate: Array<RadiologistGradeChargesCreate>, options?: any): AxiosPromise<any> {
            return localVarFp.createRadiologistGradeChargesBulk(radiologistGradeChargesCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Radiologist Grade Charge
         * @param {number} chargeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRadiologistGradeCharge(chargeId: number, options?: any): AxiosPromise<any> {
            return localVarFp.deleteRadiologistGradeCharge(chargeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Radiologist Grade Charges
         * @param {number} gradeId 
         * @param {string} modality 
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRadiologistGradeCharges(gradeId: number, modality: string, sort?: string, range?: string, filter?: string, options?: any): AxiosPromise<Array<RadiologistGradeCharges>> {
            return localVarFp.getRadiologistGradeCharges(gradeId, modality, sort, range, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Radiologist Grade Charge
         * @param {number} chargeId 
         * @param {RadiologistGradeChargesUpdate} radiologistGradeChargesUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRadiologistGradeCharge(chargeId: number, radiologistGradeChargesUpdate: RadiologistGradeChargesUpdate, options?: any): AxiosPromise<RadiologistGradeCharges> {
            return localVarFp.updateRadiologistGradeCharge(chargeId, radiologistGradeChargesUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createRadiologistGradeCharge operation in RadiologistGradeChargesApi.
 * @export
 * @interface RadiologistGradeChargesApiCreateRadiologistGradeChargeRequest
 */
export interface RadiologistGradeChargesApiCreateRadiologistGradeChargeRequest {
    /**
     * 
     * @type {RadiologistGradeChargesCreate}
     * @memberof RadiologistGradeChargesApiCreateRadiologistGradeCharge
     */
    readonly radiologistGradeChargesCreate: RadiologistGradeChargesCreate
}

/**
 * Request parameters for createRadiologistGradeChargesBulk operation in RadiologistGradeChargesApi.
 * @export
 * @interface RadiologistGradeChargesApiCreateRadiologistGradeChargesBulkRequest
 */
export interface RadiologistGradeChargesApiCreateRadiologistGradeChargesBulkRequest {
    /**
     * 
     * @type {Array<RadiologistGradeChargesCreate>}
     * @memberof RadiologistGradeChargesApiCreateRadiologistGradeChargesBulk
     */
    readonly radiologistGradeChargesCreate: Array<RadiologistGradeChargesCreate>
}

/**
 * Request parameters for deleteRadiologistGradeCharge operation in RadiologistGradeChargesApi.
 * @export
 * @interface RadiologistGradeChargesApiDeleteRadiologistGradeChargeRequest
 */
export interface RadiologistGradeChargesApiDeleteRadiologistGradeChargeRequest {
    /**
     * 
     * @type {number}
     * @memberof RadiologistGradeChargesApiDeleteRadiologistGradeCharge
     */
    readonly chargeId: number
}

/**
 * Request parameters for getRadiologistGradeCharges operation in RadiologistGradeChargesApi.
 * @export
 * @interface RadiologistGradeChargesApiGetRadiologistGradeChargesRequest
 */
export interface RadiologistGradeChargesApiGetRadiologistGradeChargesRequest {
    /**
     * 
     * @type {number}
     * @memberof RadiologistGradeChargesApiGetRadiologistGradeCharges
     */
    readonly gradeId: number

    /**
     * 
     * @type {string}
     * @memberof RadiologistGradeChargesApiGetRadiologistGradeCharges
     */
    readonly modality: string

    /**
     * Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
     * @type {string}
     * @memberof RadiologistGradeChargesApiGetRadiologistGradeCharges
     */
    readonly sort?: string

    /**
     * Format: &#x60;[start, end]&#x60;
     * @type {string}
     * @memberof RadiologistGradeChargesApiGetRadiologistGradeCharges
     */
    readonly range?: string

    /**
     * Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
     * @type {string}
     * @memberof RadiologistGradeChargesApiGetRadiologistGradeCharges
     */
    readonly filter?: string
}

/**
 * Request parameters for updateRadiologistGradeCharge operation in RadiologistGradeChargesApi.
 * @export
 * @interface RadiologistGradeChargesApiUpdateRadiologistGradeChargeRequest
 */
export interface RadiologistGradeChargesApiUpdateRadiologistGradeChargeRequest {
    /**
     * 
     * @type {number}
     * @memberof RadiologistGradeChargesApiUpdateRadiologistGradeCharge
     */
    readonly chargeId: number

    /**
     * 
     * @type {RadiologistGradeChargesUpdate}
     * @memberof RadiologistGradeChargesApiUpdateRadiologistGradeCharge
     */
    readonly radiologistGradeChargesUpdate: RadiologistGradeChargesUpdate
}

/**
 * RadiologistGradeChargesApi - object-oriented interface
 * @export
 * @class RadiologistGradeChargesApi
 * @extends {BaseAPI}
 */
export class RadiologistGradeChargesApi extends BaseAPI {
    /**
     * 
     * @summary Create Radiologist Grade Charge
     * @param {RadiologistGradeChargesApiCreateRadiologistGradeChargeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RadiologistGradeChargesApi
     */
    public createRadiologistGradeCharge(requestParameters: RadiologistGradeChargesApiCreateRadiologistGradeChargeRequest, options?: AxiosRequestConfig) {
        return RadiologistGradeChargesApiFp(this.configuration).createRadiologistGradeCharge(requestParameters.radiologistGradeChargesCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Radiologist Grade Charges Bulk
     * @param {RadiologistGradeChargesApiCreateRadiologistGradeChargesBulkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RadiologistGradeChargesApi
     */
    public createRadiologistGradeChargesBulk(requestParameters: RadiologistGradeChargesApiCreateRadiologistGradeChargesBulkRequest, options?: AxiosRequestConfig) {
        return RadiologistGradeChargesApiFp(this.configuration).createRadiologistGradeChargesBulk(requestParameters.radiologistGradeChargesCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Radiologist Grade Charge
     * @param {RadiologistGradeChargesApiDeleteRadiologistGradeChargeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RadiologistGradeChargesApi
     */
    public deleteRadiologistGradeCharge(requestParameters: RadiologistGradeChargesApiDeleteRadiologistGradeChargeRequest, options?: AxiosRequestConfig) {
        return RadiologistGradeChargesApiFp(this.configuration).deleteRadiologistGradeCharge(requestParameters.chargeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Radiologist Grade Charges
     * @param {RadiologistGradeChargesApiGetRadiologistGradeChargesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RadiologistGradeChargesApi
     */
    public getRadiologistGradeCharges(requestParameters: RadiologistGradeChargesApiGetRadiologistGradeChargesRequest, options?: AxiosRequestConfig) {
        return RadiologistGradeChargesApiFp(this.configuration).getRadiologistGradeCharges(requestParameters.gradeId, requestParameters.modality, requestParameters.sort, requestParameters.range, requestParameters.filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Radiologist Grade Charge
     * @param {RadiologistGradeChargesApiUpdateRadiologistGradeChargeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RadiologistGradeChargesApi
     */
    public updateRadiologistGradeCharge(requestParameters: RadiologistGradeChargesApiUpdateRadiologistGradeChargeRequest, options?: AxiosRequestConfig) {
        return RadiologistGradeChargesApiFp(this.configuration).updateRadiologistGradeCharge(requestParameters.chargeId, requestParameters.radiologistGradeChargesUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}

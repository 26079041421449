/* tslint:disable */
/* eslint-disable */
/**
 * eminence-pac-system
 * eminence-pac-system API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { HTTPValidationError } from '../models';
// @ts-ignore
import { Msg } from '../models';
/**
 * UtilsApi - axios parameter creator
 * @export
 */
export const UtilsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Test Celery worker.
         * @summary Test Celery
         * @param {Msg} msg 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testCelery: async (msg: Msg, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'msg' is not null or undefined
            assertParamExists('testCelery', 'msg', msg)
            const localVarPath = `/api/v1/test-celery/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(msg, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilsApi - functional programming interface
 * @export
 */
export const UtilsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UtilsApiAxiosParamCreator(configuration)
    return {
        /**
         * Test Celery worker.
         * @summary Test Celery
         * @param {Msg} msg 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testCelery(msg: Msg, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Msg>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testCelery(msg, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UtilsApi - factory interface
 * @export
 */
export const UtilsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UtilsApiFp(configuration)
    return {
        /**
         * Test Celery worker.
         * @summary Test Celery
         * @param {Msg} msg 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testCelery(msg: Msg, options?: any): AxiosPromise<Msg> {
            return localVarFp.testCelery(msg, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for testCelery operation in UtilsApi.
 * @export
 * @interface UtilsApiTestCeleryRequest
 */
export interface UtilsApiTestCeleryRequest {
    /**
     * 
     * @type {Msg}
     * @memberof UtilsApiTestCelery
     */
    readonly msg: Msg
}

/**
 * UtilsApi - object-oriented interface
 * @export
 * @class UtilsApi
 * @extends {BaseAPI}
 */
export class UtilsApi extends BaseAPI {
    /**
     * Test Celery worker.
     * @summary Test Celery
     * @param {UtilsApiTestCeleryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsApi
     */
    public testCelery(requestParameters: UtilsApiTestCeleryRequest, options?: AxiosRequestConfig) {
        return UtilsApiFp(this.configuration).testCelery(requestParameters.msg, options).then((request) => request(this.axios, this.basePath));
    }
}

/* tslint:disable */
/* eslint-disable */
/**
 * eminence-pac-system
 * eminence-pac-system API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum Modality {
    Ct = 'CT',
    Mr = 'MR',
    Xray = 'XRAY',
    Xr = 'XR',
    Dx = 'DX',
    Cr = 'CR',
    Dr = 'DR',
    Mg = 'MG',
    Cd = 'CD',
    Dsa = 'DSA',
    Nm = 'NM',
    Pet = 'PET',
    Us = 'US',
    Xa = 'XA',
    Sc = 'SC',
    Ot = 'OT'
}


